import React, { useEffect, useState, useMemo, useCallback, forwardRef, useRef } from 'react';


import { alertRoutine } from '../../../../redux/Alerts/routines'
import { store, i18n } from '../../../../index'

import { useSelector, useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next';
import { Box, Grid, InputAdornment, TextField } from '@material-ui/core';
import { useForm, useController, Controller } from "react-hook-form";
import { AjvValidatorContext } from '../../../../utils/context/AjvContext';
import { min } from 'lodash';
import { useKey } from 'react-use';
import { makeParamsSelector } from '../../../../redux/Params/selectors';
import { postParamRoutinePromiseCreator, putParamRoutinePromiseCreator } from '../../../../redux/Params/routines';

const schema = {
  type: 'object',
  properties: {
    scaleX: {
      type: 'number',
      minimum: 0
    },
    ppmm: {
      type: 'string',
      pattern: "^(\\d*([.,]\\d*)?)$",
      flags: "gm" // Ajout des options "g" et "m"
      
    },
    ppi: {
      type: 'string',
      pattern: "^(\\d*([.,]\\d*)?)$",
      flags: "gm" // Ajout des options "g" et "m"
    }
  }
}

function getPPI(scaleX) {
  return 25.4 * (202*scaleX)/50
}

function getPPMM(scaleX) {
  return (202*scaleX)/50
}

function PPItoScaleX(ppi) {
  return (ppi * 50) / 202 / 25.4
}

function PPMMtoScaleX(ppmm) {
  return (ppmm * 50) / 202
}

const Form = ({ submitRef = {}, rulerState = { scaleX: 1},  ...rest}) => {
    
  //const dispatch = useDispatch()
  const { t } = useTranslation();
  const { ajvResolver } = React.useContext(AjvValidatorContext);

  const { register, handleSubmit, control, formState: { errors }, setValue, val} = useForm({
    mode: "onChange",
    revalidateMode: "onChange",
    defaultValues: {
      scaleX: rulerState.scaleX,
      ppmm: getPPMM(rulerState.scaleX).toFixed(1),
      ppi: getPPI(rulerState.scaleX).toFixed(1),
    },
    resolver: ajvResolver(schema)
  });
  

  submitRef.current =  async () => {
    let dataRef;
    await handleSubmit(async (data) => {
      dataRef = data;
    })()
    return dataRef;
  }

  return (
    <form style={{display: 'flex', position: 'relative'}} >
      <Grid container spacing={2} style={{display: 'block'}}>
        <input type="hidden" {...register("scaleX")} />
        {/* Champ pour afficher la conversion en PPMM */}
        <Grid item xs={12}>
          {t('cephalo:interface.save this calibration as default calibration')}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="ppmm"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  e.stopPropagation();
                  console.log(field)
                  console.log("errors", errors)

                  const val = parseFloat(e.target.value);
                  console.log('val', val, !isNaN(val) && val > 0)
                  if (!isNaN(val) && val > 0) {
                    setValue('scaleX', PPMMtoScaleX(val), { shouldValidate: true });
                    setValue('ppi', getPPI(PPMMtoScaleX(val)).toFixed(1), { shouldValidate: true });
                    field.onChange(e.target.value)
                  }
                  else {
                    setValue('scaleX', null, { shouldValidate: true });
                    setValue('ppi', '', { shouldValidate: true });
                    field.onChange(e.target.value)
                  }
                  //field.onChange(e.target.value)
                  // const ppi = getPPI(val);
                  // setValue('ppi', ppi);
                  // setValue('scaleX', PPMMtoScaleX(val));
                }}
                error={errors.ppmm ? true : false}
                helperText={errors.ppmm ? t("validation:ajv.type", {type: 'number'}) : ''}
                label={t('cephalo.ppmm')}
                fullWidth
                //disabled // Ce champ est en lecture seule
                InputProps={{
                  endAdornment: <InputAdornment position="end">Px/mm</InputAdornment>,
                }}
              />
            )}
          />
        </Grid>

        {/* Champ pour afficher la conversion en PPI */}
        <Grid item xs={12}>
          <Controller
            name="ppi"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => {
                  e.stopPropagation();
                  console.log(field)
                  console.log("errors", errors)

                  const val = parseFloat(e.target.value);
                  if (!isNaN(val) && val > 0) {
                    setValue('scaleX', PPItoScaleX(val), { shouldValidate: true });
                    setValue('ppmm', getPPMM(PPItoScaleX(val)).toFixed(1), { shouldValidate: true });
                    field.onChange(e.target.value)
                  }
                  else {
                    setValue('scaleX', null, { shouldValidate: true });
                    setValue('ppmm', '', { shouldValidate: true });
                    field.onChange(e.target.value)
                  }
                  //field.onChange(e.target.value)
                  // const ppi = getPPI(val);
                  // setValue('ppi', ppi);
                  // setValue('scaleX', PPMMtoScaleX(val));
                }}
                error={errors.ppi ? true : false}
                helperText={errors.ppi ? t("validation:ajv.type", {type: 'number'}) : ''}
                label={t('cephalo.PPI')}
                fullWidth
                //disabled // Ce champ est en lecture seule
                InputProps={{
                  endAdornment: <InputAdornment position="end">PPI</InputAdornment>,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  )
}

const errorActionCreator = ({container}) => (
  alertRoutine.show({
    type: 'snack',
    title: i18n.t('common:interface.an error occured'),
    severity: 'error',
    container
  })
)

export const rulerFormActionCreator = ({rulerState, container}) => {
  
  const submitRef = { current: (x) => console.log('old submit') }
  const paramSelector = makeParamsSelector()
  return (
    alertRoutine.show({
      container,
      type: 'dialog',
      // maxWidth: 'xs',
      title: i18n.t('cephalo:interface.calibration'),
      content: <Form submitRef={submitRef} rulerState={rulerState}/>,
      fullScreenSmallScreen: true,
      scroll: 'body',
      disableEnforceFocus: true,
      secondaryActions: [
        //Ajourter un bouton pour supprimer le paramètre
        // {
        //   title: i18n.t('common:button.delete'),
        //   color: theme().palette.error.main,
        //   action: (parentAlertId) => store.dispatch(deleteTaskFormActionCreator(task, parentAlertId))
        // },
      ],
      actionTitle: i18n.t('common:button.save'),
      action:  async (values, progress) => {      
        
        try {
          progress.handleTrigger()
          console.log('handle submit')
          const values =  await submitRef.current()
          if(!values) return;

          let promise;

          //save param
          const params = paramSelector(store.getState(), 'cephalo-ruler-state')
          const rulerParam = params[0]
          if(rulerParam && rulerParam.id)
            promise = await putParamRoutinePromiseCreator({
              id: rulerParam.id,
              values: {
                value: values.scaleX
              }
            }, store.dispatch)
          else
            promise = await postParamRoutinePromiseCreator({
              name: 'cephalo-ruler-state',
              value: values.scaleX
            }, store.dispatch)


          await promise; 
          console.log('after submit')
    
          return true
        }
        catch (e) {
          //afficher l'erreur
          store.dispatch(errorActionCreator({container}))
          return false
        }
        finally {
          progress.handleFulfill()
        }
     
      }
    })
  )
}