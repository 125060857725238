import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import storageSession from 'redux-persist/lib/storage/session';
import Cookies from 'cookies-js';

import { authReducer } from './redux/Auth/reducers';
import { usersReducer } from './redux/Users/reducers';
import { patientsReducer } from './redux/Patients/reducers';
import { documentsReducer } from './redux/Documents/reducers';
import { alertsReducer } from './redux/Alerts/reducers';
import { paramsReducer } from './redux/Params/reducers';
import { importsFilesReducer } from './redux/ImportsFiles/reducers';
import { entitiesReducer } from './redux/Entities/reducers' ;
import { cephaloReducer } from './redux/Cephalos/reducers';
import { contactsReducer } from './redux/Contacts/reducers';
import { diagnosticReducer } from './redux/Diagnostic/reducers';
import { tasksReducer } from './redux/Tasks/reducers';

const persistAuthConfig = {
  key: "auth",
  storage: new CookieStorage(Cookies, {
    expiration: {
      'default': 30 * 86400 // Cookies expire after one year
    }
  }),
  blacklist: ['loading', 'error']
}

const persistParamsConfig = {
  key: "params",
  storage: new CookieStorage(Cookies, {
    expiration: {
      'default': 365 * 86400 // Cookies expire after one year
    }
  }/*, options */),
  blacklist: ['global']
}

const persistPatientsConfig = {
  key: "patients",
  storage: new CookieStorage(Cookies, {
    expiration: {
      'default': 365 * 86400 // Cookies expire after one year
    }
  }/*, options */),
  blacklist: ['patients', 'tags', 'externalLink', 'searchAdvancedPatients']
}

const persistDocumentsConfig = {
  key: "documents",
  storage: storageSession,
  blacklist: ['folderTitles', 'documents', 'aiDocuement']
}

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: persistReducer(persistAuthConfig, authReducer),
  params: persistReducer(persistParamsConfig, paramsReducer),
  users: usersReducer,
  patients: persistReducer(persistPatientsConfig, patientsReducer),
  documents: persistReducer(persistDocumentsConfig, documentsReducer),
  alerts: alertsReducer,
  importsFiles: importsFilesReducer,
  cephalo: cephaloReducer,
  tasks: tasksReducer,
  contacts: contactsReducer,
  diagnostic: diagnosticReducer,
  entities: entitiesReducer,
})

export default createRootReducer