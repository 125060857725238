import axios from 'axios';
import { tokenSelector } from '../redux/Auth/selectors'

export let apiURL
//if(window.location.hostname === 'localhost')
if(process.env.NODE_ENV === 'development')
apiURL = `http://${window.location.hostname}:1337`;
else if(process.env.NODE_ENV === 'production')
apiURL = process.env.REACT_APP_API_PROXY//'https://api.cephalomax.com'
//  url = `${window.location.protocol}//${window.location.hostname}/api`;

const api = axios.create({
  baseURL: apiURL,
});

api.defaults.withCredentials = true;

const CancelToken = axios.CancelToken;

export const connexionUrl = `${apiURL}/connect/google`

export const getToken = tokenSelector

export const auth = {
  login: qs => api.get(`auth/google/callback${qs}`)
}

export const users = {
  me: token => api.get(`users/me`, {headers: {'Authorization': `Bearer ${token}`}}),
  post: (token, data) => {
    if(data instanceof FormData)
      return api.post(`usersdata`, data, { headers: {'Authorization': `Bearer ${token}`, 'content-type': 'multipart/form-data'}})
    else
      return api.post(`usersdata`, data, { headers: {'Authorization': `Bearer ${token}`}})
  },
  put: (token, id, data) => {
    if(data instanceof FormData)
      return api.put(`usersdata/${id}`, data, { headers: {'Authorization': `Bearer ${token}`, 'content-type': 'multipart/form-data'}})
    else
      return api.put(`usersdata/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}})
  },
  put_subscription_decline: (token, data) => api.put(`stripe-subscriptions/decline`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put_subscription_accept_cgv: (token, data) => api.put(`stripe-subscriptions/accept_cgv`, data, { headers: {'Authorization': `Bearer ${token}`}}),
}

export const patients = {
  getExternal: (token, service, id) => api.get(`patients/external/${service}/${id}`, {headers: {'Authorization': `Bearer ${token}`}}),
  get: (token, id) => api.get(`patients/${id}`, {headers: {'Authorization': `Bearer ${token}`}}),
  gets: (token, ids) => api.get(`patients`, {headers: {'Authorization': `Bearer ${token}`}, params: { id_in: ids }}),
  post: (token, data) => api.post(`patients`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`patients/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  search: (token, data) => api.get(`patients/search`, {headers: {'Authorization': `Bearer ${token}`} , params: {_q: data.search, _sort: 'lastname:ASC', _start: data.start, _limit: data.limit}}),
  search_count: (token, data) => api.get(`patients/count`, {headers: {'Authorization': `Bearer ${token}`} , params: {_q: data.search }}),
  getsTags: (token) => api.get(`patients/tags`, {headers: {'Authorization': `Bearer ${token}`}}),
  getUpdate: (token, id) => api.get(`patients/update/${id}`, {headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`patients/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
  advancedSearch: (token, data) => api.get(`patients/advanced_search`, {headers: {'Authorization': `Bearer ${token}`} , params: data}),
}

export const contacts = {
  get: (token, id) => api.get(`contacts/${id}`, {headers: {'Authorization': `Bearer ${token}`}}),
  gets: (token, ids) => api.get(`contacts`, {headers: {'Authorization': `Bearer ${token}`}, params: { id_in: ids }}),
  post: (token, data) => api.post(`contacts`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`contacts/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`contacts/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
}

export const tasks = {
  get: (token, id) => api.get(`tasks/${id}`, {headers: {'Authorization': `Bearer ${token}`}}),
  gets: (token, ids) => api.get(`tasks`, {headers: {'Authorization': `Bearer ${token}`}, params: { id_in: ids }}),
  post: (token, data) => api.post(`tasks`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`tasks/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`tasks/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
  search: (token, data) => api.get(`tasks`, {headers: {'Authorization': `Bearer ${token}`} , params: {done_null: data.filter === 'ACTIVE' ? true : false, due_date_lte: data.due_date, _sort: 'due_date:ASC', _start: data.start, _limit: data.limit}}),
  search_count: (token, data) => api.get(`tasks/count`, {headers: {'Authorization': `Bearer ${token}`} , params: {done_null: data.filter === 'ACTIVE' ? true : false, ...(data.due_date ? {due_date_lte: data.due_date } : {})}}),
}

export const documents = {
  get: (token, id) => api.get(`docs/${id}`, {headers: {'Authorization': `Bearer ${token}`}}),
  post: (token, data) => api.post(`docs`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`docs/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`docs/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
  gets: (token, ids) => api.get(`docs`, {headers: {'Authorization': `Bearer ${token}`} , params: { id_in: ids }}),
  getTitles: (token) => api.get(`docs/titles`, {headers: {'Authorization': `Bearer ${token}`}}),
  getTemplates: (token) => api.get(`docs/templates`, {headers: {'Authorization': `Bearer ${token}`}}),
  postTemplate: (token, data) => api.post(`docs/templates`, data, {headers: {'Authorization': `Bearer ${token}`}}),
  copyTemplate: (token, data) => api.post(`docs/templates/copy`, data, {headers: {'Authorization': `Bearer ${token}`}}),
  upload: (uploadUrl, file, onProgress, cancel) => axios.request({
    url: uploadUrl, 
    data: file, 
    method: 'PUT',
    headers: {
      //'Content-length': file.size,
      'Content-Type': file.type,
      'Content-Range': "bytes " + 0 + "-" + (file.size - 1) + "/" + file.size,
    },
    onUploadProgress: onProgress,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    })
  }),
  getAIDocument: (token, params) => api.get('analyse_ia', {headers: {'Authorization': `Bearer ${token}`} , params})
}

export const params = {
  get: (token, id) => api.get(`params/${id}`, {headers: {'Authorization': `Bearer ${token}`}}),
  post: (token, data) => api.post(`params`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`params/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`params/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
  gets: (token, data) => api.get(`params`, {headers: {'Authorization': `Bearer ${token}`} , params: data})
}

export const cephalo_points = {
  get: (token, ids) => api.get(`cephalo-points`, {headers: {'Authorization': `Bearer ${token}`}}),
  post: (token, data) => api.post(`cephalo-points`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`cephalo-points/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`cephalo-points/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
}

export const cephalo_plans = {
  get: (token, ids) => api.get(`cephalo_plans`, {headers: {'Authorization': `Bearer ${token}`}}),
  post: (token, data) => api.post(`cephalo_plans`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`cephalo_plans/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`cephalo_plans/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
}

export const cephalo_mesures = {
  get: (token, ids) => api.get(`cephalo_mesures`, {headers: {'Authorization': `Bearer ${token}`}}),
  post: (token, data) => api.post(`cephalo_mesures`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`cephalo_mesures/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`cephalo_mesures/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
}

export const cephalo_analyses = {
  get: (token, ids) => api.get(`cephalo_analyses`, {headers: {'Authorization': `Bearer ${token}`}}),
  post: (token, data) => api.post(`cephalo_analyses`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  put: (token, id, data) => api.put(`cephalo_analyses/${id}`, data, { headers: {'Authorization': `Bearer ${token}`}}),
  delete: (token, id) => api.delete(`cephalo_analyses/${id}`, { headers: {'Authorization': `Bearer ${token}`}}),
}


