import _ from 'lodash'


export const capitalizeName = (string = "") => {
  let indexes = [];
  _.deburr(string).replace(/\b[a-zA-Z]/g, (x, index) => indexes.push(index));
  let newString = ''
  for (let i = 0; i < string.length; i++) {
    if(indexes.indexOf(i) !== -1)
      newString += string.charAt(i).toUpperCase();
    else
      newString += string.charAt(i).toLowerCase();
  }
  return newString;
}

export const capitalizeInitials = (string) => {
  const initials = _.deburr(string).match(/\b[a-zA-Z]/g) || [];
  return `${initials.join('-').toUpperCase()}.`;
}

export const capitalizeInitialsWithoutDot = (string) => {
  const initials = _.deburr(string).match(/\b[a-zA-Z]/g) || [];
  return `${initials.join('-').toUpperCase()}`;
}

export const capitalizeInitialsWithoutDotAndCaret = (string) => {
  const initials = _.deburr(string).match(/\b[a-zA-Z]/g) || [];
  return `${initials.join('').toUpperCase()}`;
}

export function stringToHslColor(str, s, l) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDomainWithSubdomain(url) {
  try {
      // Analyser l'URL
      const parsedUrl = new URL(url);

      // Extraire le nom d'hôte
      const host = parsedUrl.hostname;

      // Trouver la longueur des parties du domaine
      const domainParts = host.split('.');

      // Si le domaine contient plus de deux parties (ex : "sub.example.com")
      if (domainParts.length > 2) {
          return domainParts.slice(-3).join('.'); // Retourne le domaine avec le sous-domaine
      }

      // Sinon, il n'y a pas de sous-domaine (ex : "example.com")
      return host;
  } catch (e) {
      console.error('Erreur lors de l\'analyse de l\'URL:', e);
      return null;
  }
}

export function getDomainName(url) {
  try {
      // Analyser l'URL
      const parsedUrl = new URL(url);

      // Extraire l'hôte (nom d'hôte)
      const host = parsedUrl.hostname;

      // Diviser l'hôte en parties
      const domainParts = host.split('.');

      // Liste des extensions de domaine courantes à prendre en compte
      const domainExtensions = [
          "com", "org", "net", "int", "edu", "gov", "mil", "co", "io", "co.uk", "com.au", "org.uk", "ac.uk"
      ];

      // Si le domaine contient plus de deux parties, cela signifie qu'il y a un sous-domaine
      if (domainParts.length > 2) {
          // Vérifie si les 2 dernières parties sont une extension de domaine (ex: "co.uk")
          const lastTwoParts = domainParts.slice(-2).join('.');
          if (domainExtensions.includes(lastTwoParts)) {
              // Si c'est une extension composée (ex: "co.uk"), on ne garde que la partie avant
              return domainParts.slice(-3, -2).join('.');
          } else {
              // Sinon, on garde les 2 dernières parties (domaine principal)
              return domainParts.slice(-2, -1).join('.');
          }
      }

      // Si l'hôte n'a que deux parties, c'est déjà le domaine principal (ex: "example.com")
      return domainParts[0];
  } catch (e) {
      console.error('Erreur lors de l\'analyse de l\'URL:', e);
      return null;
  }
}
