import { createSelector } from 'reselect'
import _ from 'lodash'
import moment from 'moment'

import { patientsSelector } from '../Patients/selectors'
import { updateDocumentsByFolderSelector } from '../ImportsFiles/selectors'

export const aiIsLoadingSelector = state => state.documents.aiDocuement.loading

export const documentsIsLoadingSelector = state => state.documents.documents.loading
export const documentsIsErrorSelector = state => state.documents.documents.error

export const documentsSelector = state => state.entities.documents

export const documentsOrderSelector = state => state.documents.documents.result

 
export const visibleDocumentsSelector = createSelector(
  documentsSelector,
  (documents = {}) => _.filter(documents, (doc) => !!doc._id)
)

export const childDocumentsByFolderSeletor = createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { folderId }) => _.filter(documents, (doc) => doc.parent_doc === folderId)
)

export const photoSerieSelector =  createSelector(
  childDocumentsByFolderSeletor,
  updateDocumentsByFolderSelector,
  (_, props) => props,
  (documents = [], updateDocuments = {}, { serie = []}) => {
    const original_documents = documents.filter(doc => doc.type === 'image' && doc.name.endsWith('_original') && !updateDocuments[doc.name.slice(0,-9)] && serie.includes(doc.name.slice(0,-9)))
    const original_documents_names = original_documents.map(doc => doc.name.slice(0,-9))
    const cropped_documents = documents.filter(doc => doc.type === 'image' && !doc.name.endsWith('_original') && !original_documents_names.includes(doc.name) && !updateDocuments[doc.name] && serie.includes(doc.name))
    
    return [...original_documents, ...cropped_documents]
  }
)

export const makeDiagFolderSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { patientId, folderId }) => {
    if(!folderId) {
      //on récupère le premier dossier de la liste
      folderId = documents.filter(doc => doc.type === 'folder' && doc.parent_patient === patientId).sort((docA, docB) => {
        if(docA.date === docB.date)
          return docA.createdAt < docB.createdAt ? 1 : -1;
        return (docA.date || docA.createdAt) < (docB.date || docB.createdAt) ? 1 : -1;
      }).slice(-1)[0]?.id
    }

    return folderId
  }
)

export const makeDiagMediaSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { patientId, folderId }) => {
    if(!folderId) {
      //on récupère le premier dossier de la liste
      folderId = documents.filter(doc => doc.type === 'folder' && doc.parent_patient === patientId).sort((docA, docB) => {
        if(docA.date === docB.date)
          return docA.createdAt < docB.createdAt ? 1 : -1;
        return (docA.date || docA.createdAt) < (docB.date || docB.createdAt) ? 1 : -1;
      }).slice(-1)[0]?.id
    }

    return [_.filter(documents, (doc) => doc.parent_doc === folderId), folderId]
  }
)


export const makeChildDocumentsSeletor = () => 
  createSelector(
    visibleDocumentsSelector,
    (_, props) => props,
    (documents, { parentId, patientId, type, types = [], filter = 'ALL' }) => {
      //console.log('%creselect child', 'color: orange', documents, parentId, patientId, type)
      if(filter === 'ALL')
        return _.filter(documents, (doc) => (doc.type === type ||  types.includes(doc.type)) && (doc.parent_doc === parentId || parentId === undefined) && doc.parent_patient === patientId).sort((a, b) => (a.date || a.createdAt) < (b.date || b.createdAt) ? -1 : (a.date || a.createdAt) > (b.date || b.createdAt) ? 1 : 0)
      else if(filter === 'ORIGINAL')
        return _.filter(documents, (doc) => doc.type === type && (doc.parent_doc === parentId || parentId === undefined) && doc.parent_patient === patientId && doc.name.endsWith('_original'))
      else if(filter === 'CROPPED')
        return _.filter(documents, (doc) => doc.type === type && (doc.parent_doc === parentId || parentId === undefined) && doc.parent_patient === patientId && !doc.name.endsWith('_original'))
    }
  )

export const patientFolderByDateSelector = 
    createSelector(
      visibleDocumentsSelector,
      (_, props) => props,
      (documents, patientId) => {
        return documents
            .filter(doc => doc.type === 'folder' && !doc.parent_doc && doc.parent_patient === patientId)
              .reduce((obj, doc) => {
                const docDate = (doc.date || doc.createdAt).substring(0, 10);
                if(obj[docDate])
                    obj[docDate].push(doc)
                  else
                    obj[docDate] = [doc]
                  return obj
              }, {})
      }
    )

export const makePatientFolderByDateSelector = () => patientFolderByDateSelector

export const makePatientDocumentsSeletor = () => 
  createSelector(
    visibleDocumentsSelector,
    (_, props) => props,
    (documents, { patientId, type, types = [], filter = 'ALL' }) => {
      //console.log('%creselect child', 'color: orange', documents, parentId, patientId, type)
      if(filter === 'ALL')
        return _.filter(documents, (doc) => (doc.type === type ||  types.includes(doc.type)) && doc.parent_patient === patientId) 
      else if(filter === 'ORIGINAL')
        return _.filter(documents, (doc) => doc.type === type && doc.parent_patient === patientId && doc.name.endsWith('_original')) 
      else if(filter === 'CROPPED')
        return _.filter(documents, (doc) => doc.type === type && doc.parent_patient === patientId && !doc.name.endsWith('_original'))
    }
  )

export const makeParentDocumentSeletor = () => 
  createSelector(
    visibleDocumentsSelector,
    (_, props) => props,
    (documents, parentId) => {
      //console.log('%creselect parent', 'color: red', documents, parentId)
      return _.find(documents, (doc) => doc.id === parentId)
    }
  )

export const folderTitlesIsLoadingSelector = state => state.documents.folderTitles.loading
export const folderTitlesIsErrorSelector = state => state.documents.folderTitles.error

export const folderTitlesOrderSelector = state => state.documents.folderTitles.result

export const folderTitlesSelector = createSelector(
  state => state.entities.folder_titles,
  folderTitlesOrderSelector,
  (folder_titles = {}, lastGet) => {
    return Object.values(_.filter(folder_titles, (folder_title, key) => lastGet.includes(key)))
  }
)

export const folderColorsSelector = createSelector(
  folderTitlesSelector,
  (folder_titles = []) => {
    return _.compact(_.uniq(_.map(folder_titles, 'color')))
  }
)

export const makePhotoIdSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { folderId, photoId, original}) => {
    return _.find(documents, doc => doc.name === `${photoId}${original ? '_original' : ''}` && doc.parent_doc === folderId && doc.type === 'image' && doc.external_id)
  }
)

export const makeAnalyseSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { folderId, orientation}) => {
    return _.find(documents, doc => doc.parent_doc === folderId && doc.type === `cephalo-${orientation}`)
  }
)

export const makeDiagnosticSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { patientId}) => {
    return _.find(documents, doc => doc.parent_patient === patientId && doc.type === `diagnostic`)
  }
)

export const makeSuperposableAnalysesSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { patientId, orientation}) => {
    return _.filter(documents, doc => 
      doc.parent_patient === patientId && 
        doc.type === `cephalo-${orientation}` && 
          doc.data && Object.keys(doc.data.paths).length && Object.keys(doc.data.points).length && doc.data.ruler
    ).map(doc => ({ ...doc, parent_doc: documents.find(d => d.id === doc.parent_doc)}))
      .sort((a, b) => (a.parent_doc.date || a.parent_doc.createdAt) < (b.parent_doc.date || b.parent_doc.createdAt) ? -1 : (a.parent_doc.date || a.parent_doc.createdAt) > (b.parent_doc.date || b.parent_doc.createdAt) ? 1 : 0)
  }
)


export const makePhotoRadioScaleSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { folderId, original = false, orientation = 'sagital'}) => {
    const photo = orientation === 'sagital' ? 
      _.find(documents, doc => doc.parent_doc === folderId && doc.name === `exo_lateral${original ? '_original' : ''}` )
      : _.find(documents, doc => doc.parent_doc === folderId && doc.name === `exo_frontal${original ? '_original' : ''}` )
    const radio = orientation === 'sagital' ? 
      _.find(documents, doc => doc.parent_doc === folderId && doc.name === `xray_lateral${original ? '_original' : ''}` )
      : _.find(documents, doc => doc.parent_doc === folderId && doc.name === `xray_frontal${original ? '_original' : ''}` )
    if(photo && radio) {
      const photoSize = {w: photo.data.cropData.width, h: photo.data.cropData.height}
      const radioSize = {w: radio.data.cropData.width, h: radio.data.cropData.height}

      const photoMin = Math.min(photoSize.w, photoSize.h)
      const radioMax = Math.max(radioSize.w, radioSize.h)

      return photoMin/radioMax
    }
    return undefined 
  }
)

export const makeDocumentSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], {documentId}) => {
    return _.find(documents, doc => doc.id === documentId)
  }
)

export const makePatientAvatarPhotoSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { patientId }) => {
    const avatarPhotoOrder = ['exo_semi_lateral_smiling', 'exo_frontal_smiling', 'exo_semi_lateral', 'exo_frontal',]
    const avatarPhotos = _.filter(documents, doc => avatarPhotoOrder.includes(doc.name) && doc.parent_patient === patientId && doc.type === 'image' && doc.external_id)
    // let avatarPhotos
    // for(let i = 0; i < avatarPhotoOrder.length; i++) {
    //   const photoType = avatarPhotoOrder[i]
    //   avatarPhotos = _.filter(documents, doc => doc.name === photoType && doc.parent_patient === patientId && doc.type === 'image' && doc.external_id)
    //   if(avatarPhotos.length)
    //     break;
    // }
    return (avatarPhotos.map(doc => ({ ...doc, parent_doc: documents.find(d => d.id === doc.parent_doc)})).filter(doc => !!doc.parent_doc).sort((a, b) => (a.parent_doc.date || a.parent_doc.createdAt) < (b.parent_doc.date || b.parent_doc.createdAt) ? -1 : (a.parent_doc.date || a.parent_doc.createdAt) > (b.parent_doc.date || b.parent_doc.createdAt) ? 1 : avatarPhotoOrder.indexOf(b.name) - avatarPhotoOrder.indexOf(a.name)).slice(-1)[0])
    //_.last(_.sortBy(avatarPhotos, ['createdAt']))
  }
)

export const makeLastPhotoSelector = () => createSelector(
  visibleDocumentsSelector,
  (_, props) => props,
  (documents = [], { photoId, patientId }) => {
    let photos = _.filter(documents, doc => doc.name === photoId && doc.parent_patient === patientId && doc.type === 'image' && doc.external_id && documents.some(d => d.id === doc.parent_doc))
    return (photos.map(doc => ({ ...doc, parent_doc: documents.find(d => d.id === doc.parent_doc)})).sort((a, b) => (a.parent_doc.date || a.parent_doc.createdAt) < (b.parent_doc.date || b.parent_doc.createdAt) ? -1 : (a.parent_doc.date || a.parent_doc.createdAt) > (b.parent_doc.date || b.parent_doc.createdAt) ? 1 : 0).slice(-1)[0]) || {}
    //_.last(_.sortBy(photos, ['createdAt'])) || {}
  }
)

export const cacheSelector = state => state.documents.cache

export const makeFullCacheSelector = () => createSelector(
  cacheSelector,
  (_, props) => props,
  (cache = {},  id) => {
    return cache[id]
  }
)

export const makeCacheSelector = () => createSelector(
  cacheSelector,
  (_, props) => props,
  (cache = {},  id) => {
    return cache[id]?.url
  }
)

export const makeCachesSelector = () => createSelector(
  cacheSelector,
  (_, props) => props,
  (cache = {},  ids = []) => {
    return _(cache).pickBy((value, key) => ids.includes(key)).mapValues(o => o?.url).value()
  }
)


export const templatesIsLoadingSelector = state => state.documents.templates.loading
export const templatesIsErrorSelector = state => state.documents.templates.error

export const templatesOrderSelector = state => state.documents.templates.result

export const makeTemplatesSelector = () => createSelector(
  state => state.entities.templates,
  templatesOrderSelector,
  (_, props) => props,
  (templates = {}, lastGet, { filter = 'ALL' }) => {
    if(filter === 'ALL')
      return Object.values(_.filter(templates, (template, key) => lastGet.includes(key)))
    else if(filter === 'SLIDES')
      return Object.values(_.filter(templates, (template, key) => lastGet.includes(key) && template.mimeType === "application/vnd.google-apps.presentation"))
    else if(filter === 'DOCS')
      return Object.values(_.filter(templates, (template, key) => lastGet.includes(key) && template.mimeType === "application/vnd.google-apps.document"))
    
  }
)

export const makePatientAgeSelector = () => createSelector(
  patientsSelector,
  documentsSelector,
  (_, props) => props,
  (patients = {}, documents = {}, { folderId }) => {
    const doc = documents[folderId]
      if(!doc)
        return ''
    const patient = patients[doc.parent_patient]
      if(!patient)
        return ''

    const totalMonths = moment(doc.date || doc.createdAt).diff(patient.dateofbirth, 'months');
    const years = parseInt(totalMonths / 12);
    const months = totalMonths % 12;

    return {
      years,
      months,
      sexe: patient.gender,
    }
    //`${years} an${years > 1 ? 's' : ''} ${months > 0 ? months : ''} ${months > 0 ? 'mois' : ''}`.trim();
  }
)

export const makePatientAgesSelector = () => createSelector(
  patientsSelector,
  documentsSelector,
  (_, props) => props,
  (patients = {}, documents = {}, { folderIds = [] }) => {
    const ages = {}
    console.log('folderIds', folderIds)
    folderIds.forEach(folderId => {
      const doc = documents[folderId]
        if(!doc)
          return ''
      const patient = patients[doc.parent_patient]
        if(!patient)
          return ''

      const totalMonths = moment(doc.date || doc.createdAt).diff(patient.dateofbirth, 'months');
      const years = parseInt(totalMonths / 12);
      const months = totalMonths % 12;

      ages[folderId] = {
        years,
        months,
        sexe: patient.gender,
      }
    })

    return ages
    
    //`${years} an${years > 1 ? 's' : ''} ${months > 0 ? months : ''} ${months > 0 ? 'mois' : ''}`.trim();
  }
)

