import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions'
import { cephaloRoutine } from './routines'

import { getPointsRoutine, putPointsRoutine, postPointsRoutine, deletePointsRoutine } from './routines'

const initialStates = {
  analyses_cephalo: {
    folderId_orientation: {
      past: [],
      present: {
        points: {
          pointId: {x : 0, y:0},
        },
        paths: {
          pathId: [{x : 0, y:0},],
        },
        current_mesure: 'current hover mesure in chart',
        current_path: 'current path selected',
        profil_transform: {
          translateX: 30,
          translateY: 30,
          scaleX: 1,
          scaleY: 1,
        },
        ruler: {
          translateX: 30,
          translateY: 30,
          scaleX: 1,
          scaleY: 1,
        },
        image_settings_media: {
          contrast : 100,
          brightness: 100,
          invert: 0,
        },     
      },
      future: [],
    }
  },
  auto_save: {
    saving: false,
  },
  points: {
    result: [],
    error: null,
    loading: false,
  },
  plans: {
    result: [],
    error: null,
    loading: false,
  },
  current_mesure: 'current hover mesure in chart',
}


export const autoSaveReducer = handleActions({
  [cephaloRoutine.AUTOSAVE_START]: (state, action) => {
    return {
      saving: true
    }
  },
  [cephaloRoutine.AUTOSAVE_END]: (state, action) => {
    return {
      saving: false,
    }
  },
},
initialStates.auto_save
)

export const currentMesureReducer = handleActions({
  [cephaloRoutine.SET_CURRENT_MESURE]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.current_mesure :  current_mesure

    return action.payload.current_mesure
  },
},
initialStates.current_mesure
)

export const analysesCephaloReducer = handleActions({
  [cephaloRoutine.UNDO]: (state, action) => {
    //action.payload.folder : folderId 
    //action.payload.orientation : orientation of the analyse
    //action.payload.analyse : analyse state
    const { past, present, future } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const previous = past[past.length - 1]
    const newPast = past.slice(0, past.length - 1)

    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: newPast,
        present: previous,
        future: [present, ...future]
      }
    }
  },
  [cephaloRoutine.REDO]: (state, action) => {
    //action.payload.folder : folderId 
    //action.payload.orientation : orientation of the analyse
    //action.payload.analyse : analyse state
    const { past, present, future } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const next = future[0]
    const newFuture = future.slice(1)

    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [...past, present],
        present: next,
        future: newFuture
      }
    }
  },
  [cephaloRoutine.LOAD_ANALYSE]: (state, action) => {
    //action.payload.folder : folderId 
    //action.payload.orientation : orientation of the analyse
    //action.payload.analyse : analyse state
    const newPresent = action.payload.analyse
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [],
        present: newPresent,
        future: [],
      }
    }
  },
  [cephaloRoutine.SET_POINT]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.point :  { pointId: {x : 0, y:0}}
    const { past, present } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const newPresent = {
      ...present,
      points: {
        ...present.points,
        ...action.payload.point
      }
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [...past, present],
        present: newPresent,
        future: []
      }
    }
  },
  [cephaloRoutine.DELETE_POINT]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.point :  pointId
    
    const { past, present } =  state[`${action.payload.folder}_${action.payload.orientation}`]

    const {[action.payload.point] : toDelete, ...restPoints} = present.points

    const newPresent = {
      ...present,
      points: restPoints
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [...past, present],
        present: newPresent,
        future: []
      }
    }
  },
  [cephaloRoutine.SET_RULER]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.ruler :  transform

    const { past, present } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const newPresent = {
      ...present,
      ruler: action.payload.ruler
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [...past, present],
        present: newPresent,
        future: []
      }
    }
  },
  [cephaloRoutine.SET_PROFIL_TRANSFORM]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.profil :  transform

    const { past, present } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const newPresent = {
      ...present,
      profil_transform: action.payload.profil
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [...past, present],
        present: newPresent,
        future: []
      }
    }
  },
  [cephaloRoutine.SET_IMAGE_SETTINGS]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.media: media
    //action.payload.image_settings :  image_settings
    const { past, present, future } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const newPresent = {
      ...present,
      [`image_settings_${action.payload.media}`]: action.payload.image_settings
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past,
        present: newPresent,
        future,
      }
    }
  },
  // [cephaloRoutine.SET_CURRENT_MESURE]: (state, action) => {
  //   //action.payload.folder : folderId
  //   //action.payload.orientation : orientation of the analyse
  //   //action.payload.current_mesure :  current_mesure

  //   const { past, present, future } =  state[`${action.payload.folder}_${action.payload.orientation}`]
  //   const newPresent = {
  //     ...present,
  //     current_mesure: action.payload.current_mesure
  //   }
  //   return {
  //     ...state,
  //     [`${action.payload.folder}_${action.payload.orientation}`]: {
  //       past,
  //       present: newPresent,
  //       future,
  //     }
  //   }
  // },
  [cephaloRoutine.SET_CURRENT_PATH]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.current_path :  current_path
    const { past, present, future } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const newPresent = {
      ...present,
      current_path: action.payload.current_path
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past,
        present: newPresent,
        future,
      }
    }
  },
  [cephaloRoutine.DELETE_PATH]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.path:  pathId

    const { past, present } =  state[`${action.payload.folder}_${action.payload.orientation}`]

    const {[action.payload.path] : toDelete, ...restPaths} = present.paths

    const newPresent = {
      ...present,
      paths: restPaths
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [...past, present],
        present: newPresent,
        future: []
      }
    }
  },
  [cephaloRoutine.SET_PATH]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.path :  { pointId: [{x : 0, y:0}]}
    const { past, present } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const newPresent = {
      ...present,
      paths: {
        ...present.paths,
        ...action.payload.path
      }
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [...past, present],
        present: newPresent,
        future: []
      }
    }
  },
  [cephaloRoutine.RESET]: (state, action) => {
    //action.payload.folder : folderId
    //action.payload.orientation : orientation of the analyse
    //action.payload.keys :  { keys: ['profile_transform', 'ruler', 'points', 'paths']}
    
    const update = {}

    action.payload.keys.forEach(key => {
      if(key === 'points' || key === 'paths')
        update[key] = {}
      else
        update[key] = null
    })

    const { past, present } =  state[`${action.payload.folder}_${action.payload.orientation}`]
    const newPresent = {
      ...present,
      ...update
    }
    return {
      ...state,
      [`${action.payload.folder}_${action.payload.orientation}`]: {
        past: [...past, present],
        present: newPresent,
        future: []
      }
    }
  },
},
initialStates.analyses_cephalo
)

const pointsReducer = handleActions({
   
  [combineActions(getPointsRoutine.TRIGGER, postPointsRoutine.TRIGGER, putPointsRoutine.TRIGGER, deletePointsRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true
  }), 
  
  //same state
  [combineActions(postPointsRoutine.SUCCESS, putPointsRoutine.SUCCESS)]: (state, action) => ({
    ...state,
    error: null,
  }),

  [combineActions(getPointsRoutine.SUCCESS)]: (state, action) => ({
    ...state,
    result: action.payload.result,
    error: null,
  }), 

  [combineActions(getPointsRoutine.FAILURE, postPointsRoutine.FAILURE, putPointsRoutine.FAILURE, deletePointsRoutine.FAILURE)]: (state, action) => ({
    ...state,
    error: action.payload
  }), 
  
  [combineActions(getPointsRoutine.FULFILL, postPointsRoutine.FULFILL, putPointsRoutine.FULFILL, deletePointsRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false
  }), 


},
initialStates.points
);


export const cephaloReducer = combineReducers({
  analyses_cephalo: analysesCephaloReducer,
  auto_save: autoSaveReducer,
  points: pointsReducer,
  current_mesure: currentMesureReducer,
})