import { createRoutine, createRoutineCreator, promisifyRoutine } from 'redux-saga-routines';

export const getAIDocumentRoutine = createRoutine('GET_AI_DOCUMENT');

export const getDocumentRoutine = createRoutine('GET_DOCUMENT'); 

export const postDocumentRoutine = createRoutine('POST_DOCUMENT'); 
export const postDocumentRoutinePromiseCreator = promisifyRoutine(postDocumentRoutine);

export const putDocumentRoutine = createRoutine('PUT_DOCUMENT');
export const putDocumentRoutinePromiseCreator = promisifyRoutine(putDocumentRoutine);

export const deleteDocumentRoutine = createRoutine('DELETE_DOCUMENT');
export const deleteDocumentRoutinePromiseCreator = promisifyRoutine(deleteDocumentRoutine);

export const getDocumentsRoutine = createRoutine('GET_DOCUMENTS');

export const getFoldertitlesRoutine = createRoutine('GET_FOLDER_TITLES');

export const getTemplatesRoutine = createRoutine('GET_TEMPLATES')

export const postTemplateRoutine = createRoutine('POST_TEMPLATE')
export const postTemplateRoutinePromiseCreator = promisifyRoutine(postTemplateRoutine);


export const copyTemplateRoutine = createRoutine('COPY_TEMPLATE')
export const copyTemplateRoutinePromiseCreator = promisifyRoutine(copyTemplateRoutine);

export const googlePickerRoutine = createRoutine('GOOGLE_PICKER')

const createDocumentCacheRoutine = createRoutineCreator([
  'GET',
  'SET',
  'DELETE',
  'DELETE_ALL',
])

export const documentCacheRoutine = createDocumentCacheRoutine('CACHE')