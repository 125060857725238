export const getDroite = (p1, p2) => {
  //d: y = ax + b
  //(x,y) = (p1x,p1y) + t(p2x-p1x, p2y-p1y)
  //x = p1x + t(p2x-p1x)
  //y = p1y + t(p2y-p1y)
  //on isole t et on obtient une équation de la forme y = ax + b
  const a = (p2.y - p1.y)/(p2.x - p1.x)
  const b = p1.y - a * p1.x
  return { a, b }
}

export const getIntersection2 = (p1, p2, p3, p4) => {
  const d1 = getDroite(p1, p2)
  const d2 = getDroite(p3, p4)
  const x = (d2.b-d1.b)/(d1.a-d2.a)
  const y = d1.a * x + d1.b
  return {x, y}
}

export const getIntersection = (p1, p2, p3, p4) => {
   
  let denominator, a, b, numerator1, numerator2, result = {}

  denominator = ((p4.y - p3.y) * (p2.x - p1.x)) - ((p4.x - p3.x) * (p2.y - p1.y));
  if (denominator === 0) {
      return {x:NaN, y:NaN};
  }
  
  a = p1.y - p3.y;
  b = p1.x - p3.x;
  numerator1 = ((p4.x - p3.x) * a) - ((p4.y - p3.y) * b);
  numerator2 = ((p2.x - p1.x) * a) - ((p2.y - p1.y) * b);
  a = numerator1 / denominator;
  b = numerator2 / denominator;

  // if we cast these lines infinitely in both directions, they intersect here:
  result.x = p1.x + (a * (p2.x - p1.x));
  result.y = p1.y + (a * (p2.y - p1.y));

  return result
}

export const getNormal = (p1, p2) => {
  // vector p1p2 p1(x1,y1) p2(x2,y2)
  //dx = x2 - x1 and dy = y2 - y1
  // normal = (-dy, dx) or (dy, -dx)
  const x = p1.y - p2.y 
  const y = p2.x - p1.x
  return {x, y}
}

export const getPointMilieu = (p1, p2) => {
  const x = p1.x + (p2.x - p1.x)/2
  const y = p1.y + (p2.y - p1.y)/2
  return {x, y}
}

export const getPointProjete = (p1, p3, p4) => {
  //projete de p1 sur p2p3
  const n = getNormal(p3, p4)
  const p2 = {x: p1.x + n.x, y: p1.y + n.y}
  return getIntersection(p1, p2, p3, p4)
}

export const getPointNormal = (p1, p3, p4) => {
  //projete de p1 sur p2p3
  const n = getNormal(p3, p4)
  const p2 = {x: p1.x + n.x, y: p1.y + n.y}
  return p2
}

export const getVector = (p1,p2) => ({x: p2.x-p1.x, y: p2.y-p1.y})

export const getAngleDeg = (v1, v2) => Math.atan2(v1.x*v2.y - v1.y*v2.x, v1.x*v2.x + v1.y*v2.y) * 180 / Math.PI;

export const getLength = (p1, p2, oriented) => {
  if(oriented === 'horizontal')
    return Math.sign(p2.x - p1.x) * Math.sqrt(Math.pow(p2.x-p1.x, 2) + Math.pow(p2.y-p1.y, 2))
  if(oriented === 'vertical')
    return Math.sign(p2.y - p1.y) * Math.sqrt(Math.pow(p2.x-p1.x, 2) + Math.pow(p2.y-p1.y, 2))

  return Math.sqrt(Math.pow(p2.x-p1.x, 2) + Math.pow(p2.y-p1.y, 2))
}

export const getRatio = (v1, v2) => v1/v2