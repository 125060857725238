import React, { useEffect, useRef, useCallback, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux'

import { cephaloRoutine } from '../../../redux/Cephalos/routines'
import { makeMatriceSelector } from '../../../redux/Cephalos/selectors'

import { makeDocumentSelector} from '../../../redux/Documents/selectors'

import { useDeepCompareEffect, useEvent, useKey } from 'react-use'

import { SVG, Matrix, Point} from '@svgdotjs/svg.js'

import fitCurve from 'fit-curve'

import { isMobile } from 'react-device-detect';
import { Menu, MenuItem } from '@material-ui/core';
import { rulerDialogActionCreator, rulerFormActionCreator } from './forms/rulerDialogActionCreator';
import { useTranslation } from 'react-i18next';

//import { Victor } from 'victor'

const useStyles = makeStyles(theme => ({
  
}))

const noop = () => {}

const offset = (x, y, draw) => {
  const {top, left} = draw.node.getBoundingClientRect()
  return [x - left, y - top]
} 

let previousScreenX, previousScreenY;


//on utilise un custom movementX et movementY car bug sous chrome windows avec ecran 4K
//https://monorail-prod.appspot.com/p/chromium/issues/detail?id=907309

const tools = {

  point : {
    mouseDown: (e, draw, hitRef) => {
      hitRef.current = {}
      previousScreenX = e.screenX
      previousScreenY = e.screenY
      if(e.target.id === 'ruler') {
        
        //right click
        if(e.button !== 0 || e.ctrlKey) {
          e.preventDefault()
          hitRef.current = null
          return
        }

        hitRef.current.ruler = true
        hitRef.current.elt = e.target.instance.parent(SVG.G)
        console.log(hitRef.current.elt.transform())
        //hitRef.current.data = offset(e.clientX, e.clientY, draw)
        return
      }

      if(e.target.id === 'ruler-point-1' || e.target.id === 'ruler-point-2') {
        hitRef.current.rulerPoint = e.target.id === 'ruler-point-1' ? 2 : 1
        hitRef.current.elt = e.target.instance.parent(SVG.G)
        
        const point2 = offset(e.clientX, e.clientY, draw)
        const point1Rect = hitRef.current.elt.find(`#ruler-point-${hitRef.current.rulerPoint}`)[0].node.getBoundingClientRect()
        const point1 = offset(point1Rect.x + point1Rect.width/2, point1Rect.y + point1Rect.height/2, draw)
        
        hitRef.current.data = [point2[0]-point1[0], point2[1]-point1[1]]
        return
      }


      hitRef.current.data = offset(e.clientX, e.clientY, draw)
      hitRef.current.elt = draw.circle(4).fill('red').center(...hitRef.current.data)
    },
    mouseMove: (e, draw, hitRef) => {

      const movementX = e.screenX - previousScreenX
      const movementY = e.screenY - previousScreenY
      previousScreenX = e.screenX
      previousScreenY = e.screenY

      if(!hitRef.current)
        return

      if(hitRef.current.ruler === true) {
        hitRef.current.elt.translate(movementX,movementY)
        //hitRef.current.elt.translate(e.movementX,e.movementY)
        return
      }

      if(hitRef.current.rulerPoint) {
        
        const newPoint = [hitRef.current.data[0] + movementX, hitRef.current.data[1] + movementY]
        //const newPoint = [hitRef.current.data[0] + e.movementX, hitRef.current.data[1] + e.movementY]

        const a1 = Math.atan2(hitRef.current.data[0], - hitRef.current.data[1] )*(180/Math.PI)
        const a2 = Math.atan2(newPoint[0], -newPoint[1])*(180/Math.PI)

        const d1 = Math.sqrt(Math.pow( hitRef.current.data[0],2) + Math.pow( hitRef.current.data[1],2))
        const d2 = Math.sqrt(Math.pow( newPoint[0],2) + Math.pow( newPoint[1],2))

        hitRef.current.elt.scale(d2/d1, ...(hitRef.current.rulerPoint === 2 ? [200, 10] : [0,10]))
        hitRef.current.elt.rotate(a2-a1, ...(hitRef.current.rulerPoint === 2 ? [200, 10] : [0,10]))

        hitRef.current.data = newPoint
        return
      }

      draw.node.style.cursor = 'none'
      hitRef.current.data = offset(e.clientX, e.clientY, draw)
      hitRef.current.elt.center(...hitRef.current.data)
      
    },
    mouseUp: (e, draw, hitRef, cb = noop) => {
      const movementX = e.screenX - previousScreenX
      const movementY = e.screenY - previousScreenY
      
      if(!hitRef.current)
        return

      if(hitRef.current.ruler === true) {
        
        hitRef.current.elt.translate(movementX,movementY)
        //hitRef.current.elt.translate(e.movementX,e.movementY)

        //callback

        cb({ruler: hitRef.current.elt.transform()})

        hitRef.current = null
        return 
      }

      if(hitRef.current.rulerPoint) {
        
        const newPoint = [hitRef.current.data[0] + movementX, hitRef.current.data[1] + movementY]
        // const newPoint = [hitRef.current.data[0] + e.movementX, hitRef.current.data[1] + e.movementY]

        const d1 = Math.sqrt(Math.pow( hitRef.current.data[0],2) + Math.pow( hitRef.current.data[1],2))
        const d2 = Math.sqrt(Math.pow( newPoint[0],2) + Math.pow( newPoint[1],2))

        hitRef.current.elt.scale(d2/d1, ...(hitRef.current.rulerPoint === 2 ? [200, 10] : [0,10]))

        const a1 = Math.atan2(hitRef.current.data[0], - hitRef.current.data[1] )*(180/Math.PI)
        const a2 = Math.atan2(newPoint[0], -newPoint[1])*(180/Math.PI)

        hitRef.current.elt.rotate(a2-a1, ...(hitRef.current.rulerPoint === 2 ? [200, 10] : [0,10]))



        //callback
        cb({ruler: hitRef.current.elt.transform()})
        hitRef.current = null
        return
      }

      draw.node.style.cursor = 'crosshair'
      hitRef.current.elt.remove()
      cb({point: offset(e.clientX, e.clientY, draw)})
      hitRef.current = null
    },
    touchStart: (e, draw, hitRef) => {
      console.log('touchstart event', e)
      
      if(!hitRef.current)
        hitRef.current = {}
      
      if(e.touches.length === 1 && !hitRef.current.scale) {
        
        previousScreenX = e.touches[0].screenX
        previousScreenY = e.touches[0].screenY
        
        if(e.target.id === 'ruler') {
          hitRef.current.ruler = true
          hitRef.current.elt = e.target.instance.parent(SVG.G)
          //hitRef.current.initialTransform = hitRef.current.elt.transform()
          //console.log(hitRef.current.elt.transform())
          //hitRef.current.data = offset(e.clientX, e.clientY, draw)
          return
        }

        if(e.target.id === 'ruler-point-1' || e.target.id === 'ruler-point-2') {
          hitRef.current.rulerPoint = e.target.id === 'ruler-point-1' ? 2 : 1
          hitRef.current.elt = e.target.instance.parent(SVG.G)
          //hitRef.current.initialTransform = hitRef.current.elt.transform()

          const point2 = offset(e.touches[0].clientX, e.touches[0].clientY, draw)
          const point1Rect = hitRef.current.elt.find(`#ruler-point-${hitRef.current.rulerPoint}`)[0].node.getBoundingClientRect()
          const point1 = offset(point1Rect.x + point1Rect.width/2, point1Rect.y + point1Rect.height/2, draw)
          
          hitRef.current.data = [point2[0]-point1[0], point2[1]-point1[1]]
          return
        }

        hitRef.current.data = offset(e.touches[0].clientX, e.touches[0].clientY, draw)
        hitRef.current.elt = draw.circle(4).fill('red').center(...hitRef.current.data)
        
      }
      if(e.touches.length === 2) {

        // //cancel previous touches action
        // if(hitRef.current.ruler) {
        //   hitRef.current.ruler = false
        //   hitRef.current.elt.transform(hitRef.current.initialTransform)
        //   return
        // }

        // if(hitRef.current.rulerPoint) {
        //   hitRef.current.rulerPoint = false
        //   hitRef.current.elt.transform(hitRef.current.initialTransform)
        //   return
        // }

        // draw.node.style.cursor = 'crosshair'
        // if(hitRef.current && hitRef.current.elt)
        //   hitRef.current.elt.remove()
      }

      
    },
    touchMove: (e, draw, hitRef) => {
      console.log('touchstart move')

      if(e.touches.length === 1 && hitRef.current && !hitRef.current.scale) {
        
        const movementX = e.touches[0].screenX - previousScreenX
        const movementY = e.touches[0].screenY - previousScreenY
        previousScreenX = e.touches[0].screenX
        previousScreenY = e.touches[0].screenY

        if(!hitRef.current)
          return

        if(hitRef.current.ruler === true) {
          hitRef.current.elt.translate(movementX,movementY)
          //hitRef.current.elt.translate(e.movementX,e.movementY)
          return
        }

        if(hitRef.current.rulerPoint) {
        
          const newPoint = [hitRef.current.data[0] + movementX, hitRef.current.data[1] + movementY]
          //const newPoint = [hitRef.current.data[0] + e.movementX, hitRef.current.data[1] + e.movementY]
  
          const a1 = Math.atan2(hitRef.current.data[0], - hitRef.current.data[1] )*(180/Math.PI)
          const a2 = Math.atan2(newPoint[0], -newPoint[1])*(180/Math.PI)
  
          const d1 = Math.sqrt(Math.pow( hitRef.current.data[0],2) + Math.pow( hitRef.current.data[1],2))
          const d2 = Math.sqrt(Math.pow( newPoint[0],2) + Math.pow( newPoint[1],2))
  
          hitRef.current.elt.scale(d2/d1, ...(hitRef.current.rulerPoint === 2 ? [200, 10] : [0,10]))
          hitRef.current.elt.rotate(a2-a1, ...(hitRef.current.rulerPoint === 2 ? [200, 10] : [0,10]))
  
          hitRef.current.data = newPoint
          return
        }
        
        draw.node.style.cursor = 'none'
        hitRef.current.data = offset(e.touches[0].clientX, e.touches[0].clientY, draw)
        hitRef.current.elt.center(...hitRef.current.data)
      }
      
    },
    touchEnd: (e, draw, hitRef, cb = noop) => {
      console.log('touchstart end', e)

      if(e.touches.length === 0 && hitRef.current && !hitRef.current.scale) {
              
        const movementX = e.changedTouches[0].screenX - previousScreenX
        const movementY = e.changedTouches[0].screenY - previousScreenY
        
        if(!hitRef.current)
          return

        if(hitRef.current.ruler === true) {
          
          hitRef.current.elt.translate(movementX,movementY)
          //hitRef.current.elt.translate(e.movementX,e.movementY)

          //callback

          cb({ruler: hitRef.current.elt.transform()})

          hitRef.current = null
          return 
        }

        if(hitRef.current.rulerPoint) {
        
          const newPoint = [hitRef.current.data[0] + movementX, hitRef.current.data[1] + movementY]
          // const newPoint = [hitRef.current.data[0] + e.movementX, hitRef.current.data[1] + e.movementY]
  
          const d1 = Math.sqrt(Math.pow( hitRef.current.data[0],2) + Math.pow( hitRef.current.data[1],2))
          const d2 = Math.sqrt(Math.pow( newPoint[0],2) + Math.pow( newPoint[1],2))
  
          hitRef.current.elt.scale(d2/d1, ...(hitRef.current.rulerPoint === 2 ? [200, 10] : [0,10]))
  
          const a1 = Math.atan2(hitRef.current.data[0], - hitRef.current.data[1] )*(180/Math.PI)
          const a2 = Math.atan2(newPoint[0], -newPoint[1])*(180/Math.PI)
  
          hitRef.current.elt.rotate(a2-a1, ...(hitRef.current.rulerPoint === 2 ? [200, 10] : [0,10]))
  
  
  
          //callback
          cb({ruler: hitRef.current.elt.transform()})
          hitRef.current = null
          return
        }

        draw.node.style.cursor = 'crosshair'
        hitRef.current.elt.remove()
        cb({point: offset(e.changedTouches[0].clientX, e.changedTouches[0].clientY, draw)})
        hitRef.current = null
        return
      }

      if(e.touches.length < 2 && hitRef.current && hitRef.current.scale) {
        //hitRef.current.scale = false
      }

      if(e.touches.length === 0)
        hitRef.current = null
    },
  },
  path: {
    mouseDown: (e, draw, hitRef) => {
      hitRef.current = {}
      hitRef.current.data = [['M', ...offset(e.clientX, e.clientY, draw)]]
      hitRef.current.elt = draw.path(hitRef.current.data)
        .stroke({ 
          color: '#f06', 
          width: 2,
          linecap: 'round', 
          linejoin: 'round',
          dasharray: '2, 10' 
        }).fill('none')
    },
    mouseMove: (e, draw, hitRef) => {
      if(!hitRef.current || !hitRef.current.data)
        return
      const prev = hitRef.current.data[hitRef.current.data.length - 1]
      const next = ['L', ...offset(e.clientX, e.clientY, draw)]
      const dist = Math.sqrt(Math.pow( next[1] - prev[1],2) + Math.pow( next[2] - prev[2],2))
      if(dist < 10)
        return
      hitRef.current.data = [...hitRef.current.data, next]
      hitRef.current.elt.plot(hitRef.current.data)
      
    },
    mouseUp: (e, draw, hitRef, cb = noop) => {
      if(!hitRef.current)
        return
      hitRef.current.elt.remove()
      hitRef.current.data = [...hitRef.current.data, ['L', ...offset(e.clientX, e.clientY, draw)]]

      if(hitRef.current.data.length > 2){
        const fittedPath = fitCurve(hitRef.current.data.map(p => [p[1], p[2]]), 20)
        cb(fittedPath) 
      }
      hitRef.current = null
    },
    touchStart: (e, draw, hitRef) => {
      
      if(!hitRef.current)
        hitRef.current = {}
      
      if(e.touches.length === 1 && !hitRef.current.scale) {
        
        hitRef.current.data = [['M', ...offset(e.touches[0].clientX, e.touches[0].clientY, draw)]]
        hitRef.current.elt = draw.path(hitRef.current.data)
        .stroke({ 
          color: '#f06', 
          width: 2,
          linecap: 'round', 
          linejoin: 'round',
          dasharray: '2, 10' 
        }).fill('none')
        
      }
      if(e.touches.length === 2) {

        // draw.node.style.cursor = 'crosshair'
        // if(hitRef.current && hitRef.current.elt)
        //   hitRef.current.elt.remove()
      }

      
    },
    touchMove: (e, draw, hitRef) => {

      if(e.touches.length === 1 && hitRef.current && !hitRef.current.scale) {
        
        if(!hitRef.current)
        return

        const prev = hitRef.current.data[hitRef.current.data.length - 1]
        const next = ['L', ...offset(e.touches[0].clientX, e.touches[0].clientY, draw)]
        const dist = Math.hypot(
          next[1] - prev[1],
          next[2] - prev[2]
        )

        if(dist < 10)
          return
        hitRef.current.data = [...hitRef.current.data, next]
        hitRef.current.elt.plot(hitRef.current.data)

        
      }
      
    },
    touchEnd: (e, draw, hitRef, cb = noop) => {

      if(e.touches.length === 0 && hitRef.current && !hitRef.current.scale) {
                      
        if(!hitRef.current)
          return
        hitRef.current.elt.remove()
        hitRef.current.data = [...hitRef.current.data, ['L', ...offset(e.changedTouches[0].clientX, e.changedTouches[0].clientY, draw)]]

        if(hitRef.current.data.length > 2){
          const fittedPath = fitCurve(hitRef.current.data.map(p => [p[1], p[2]]), 20)
          cb(fittedPath) 
        }
        
        hitRef.current = null
        return
      }

      if(e.touches.length < 2 && hitRef.current && hitRef.current.scale) {
        //hitRef.current.scale = false
      }

      if(e.touches.length === 0)
        hitRef.current = null
    },
  },
  edit: {
    mouseDown: (e, draw, hitRef) => {
      
      previousScreenX = e.screenX
      previousScreenY = e.screenY

      if((e.target.instance.attr('type') === 'path-select' || e.target.instance.attr('type') === 'path') && e.altKey) {
        console.log(e)
        //voir si on ajoute shift
        hitRef.current = {}
        draw.find('[type="center-point"]').fill('white')
        const newPoint = offset(e.clientX, e.clientY, draw);
        
        const pathArray = e.target.instance.array();

        const interpolate = (A,B,t) => {
          // a virtual line from A to B
          // get the position of a point on a line
          // if(t == .5) the point in inthe center of the line
          let ry = [
            ((B[0] - A[0])  * t) + A[0],//x
            ((B[1] - A[1])  * t) + A[1] //y
          ]
          return ry;
        }

        const getHelperPoints = (start, cp1, cp2, end, tV) => {
          
          let points = [start, cp1, cp2, end]
          let helperPoints = [];

          // helper points 0,1,2
          for (let i = 1; i < 4; i++) {
            //points.length must be 4 !!!
            let p = interpolate(points[i - 1], points[i], tV);
            helperPoints.push(p);
          }
          // helper points 3,4
          helperPoints.push(interpolate(helperPoints[0], helperPoints[1], tV));
          helperPoints.push(interpolate(helperPoints[1], helperPoints[2], tV));

          // helper point 5 is where the first Bézier ends and where the second Bézier begins
          helperPoints.push(interpolate(helperPoints[3], helperPoints[4], tV));

          helperPoints.push(start)
          helperPoints.push(end)

          return helperPoints
        }

        let closestPoint;
        let closestDistance = Number.MAX_VALUE;
        let closestHelperPoints;

        pathArray.forEach((curve, index)=> {
          if(index === 0)
            return
          const start = pathArray[index - 1].slice(-2)
          const [cp1, cp2, end] = [curve.slice(-6, -4), curve.slice(-4, -2), curve.slice(-2)]

          for(let i = 0 ; i < 1; i += 0.005) {
            
            const helperPoints = getHelperPoints(start, cp1, cp2, end, i)

            const interpolatedDist = Math.hypot(newPoint[0] - helperPoints[5][0], newPoint[1] - helperPoints[5][1])
            if (interpolatedDist < closestDistance) {
              closestPoint = index - 1;
              closestDistance = interpolatedDist;
              closestHelperPoints = helperPoints
            }
          }
        })
        const newArray = [
          ...pathArray.slice(0, closestPoint + 1), 
          ['C', closestHelperPoints[0][0], closestHelperPoints[0][1], closestHelperPoints[3][0], closestHelperPoints[3][1], closestHelperPoints[5][0], closestHelperPoints[5][1]],
          ['C', closestHelperPoints[4][0], closestHelperPoints[4][1], closestHelperPoints[2][0], closestHelperPoints[2][1], closestHelperPoints[7][0], closestHelperPoints[7][1]],
          ...pathArray.slice(closestPoint + 2),
        ]

        const newPointGroup = draw.group()
        const pathIndex = e.target.instance.attr('pathIndex')

        newPointGroup.line(...closestHelperPoints[3], ...closestHelperPoints[5]).stroke({ width: 2, color: 'blue'}).attr({
          type: 'line',
          pathIndex: pathIndex,
          curveIndex: closestPoint + 1,
          handleIndex: 0
        }).back().attr("vector-effect", "non-scaling-stroke")

        newPointGroup.line(...closestHelperPoints[5], ...closestHelperPoints[4]).stroke({ width: 2, color: 'blue'}).attr({
          type: 'line',
          pathIndex: pathIndex,
          curveIndex: closestPoint + 1,
          handleIndex: 0
        }).back().attr("vector-effect", "non-scaling-stroke")
        
        
        newPointGroup.circle(4).center(...closestHelperPoints[3]).fill('white').stroke({ width: 1, color: 'blue'}).attr({
          type: 'handle-point',
          pathIndex: pathIndex,
          curveIndex: closestPoint + 1,
          handleIndex: 0
        }).attr("vector-effect", "non-scaling-stroke")
        newPointGroup.circle(10)
          .fill('transparent')
            .stroke({width: 2, color: 'transparent'})
              .center(...closestHelperPoints[3])
                  .css('cursor', 'pointer')
                    .on('mouseenter', function () {
                      this.stroke({width: 2, color: 'red'})
                    }).on('mouseout', function () {
                      this.stroke({width: 2, color: 'transparent'})
                    }).attr({
                      type: 'handle',
                      pathIndex: pathIndex,
                      curveIndex: closestPoint + 1,
                      handleIndex: 0
                    }).attr("vector-effect", "non-scaling-stroke")


        newPointGroup.circle(4).center(...closestHelperPoints[4]).fill('white').stroke({ width: 1, color: 'blue'}).attr({
          type: 'handle-point',
          pathIndex: pathIndex,
          curveIndex: closestPoint + 1,
          handleIndex: 1
        }).attr("vector-effect", "non-scaling-stroke")
        newPointGroup.circle(10)
          .fill('transparent')
            .stroke({width: 2, color: 'transparent'})
              .center(...closestHelperPoints[4])
                  .css('cursor', 'pointer')
                    .on('mouseenter', function () {
                      this.stroke({width: 2, color: 'red'})
                    }).on('mouseout', function () {
                      this.stroke({width: 2, color: 'transparent'})
                    }).attr({
                      type: 'handle',
                      pathIndex: pathIndex,
                      curveIndex: closestPoint + 1,
                      handleIndex: 1
                    }).attr("vector-effect", "non-scaling-stroke")


        newPointGroup.circle(5).center(...closestHelperPoints[5]).fill('red').stroke({ width: 1, color: 'blue'}).attr("vector-effect", "non-scaling-stroke")
        newPointGroup.circle(10)
          .fill('transparent')
            .stroke({width: 2, color: 'transparent'})
              .center(...closestHelperPoints[5])
                  .css('cursor', 'pointer')
                    .on('mouseenter', function () {
                      this.stroke({width: 2, color: 'red'})
                    }).on('mouseout', function () {
                      this.stroke({width: 2, color: 'transparent'})
                    }).attr({
                      type: 'point'
                    }).attr("vector-effect", "non-scaling-stroke")
        
        const previousLine = draw.findOne(`line[pathIndex="${pathIndex}"][curveIndex="${closestPoint+1}"][handleIndex="${0}"]`)
        const previousHandle = draw.findOne(`circle[type="handle-point"][pathIndex="${pathIndex}"][curveIndex="${closestPoint+1}"][handleIndex="${0}"]`)

        const nextLine = draw.findOne(`line[pathIndex="${pathIndex}"][curveIndex="${closestPoint+1}"][handleIndex="${1}"]`)
        const nextHandle = draw.findOne(`circle[type="handle-point"][pathIndex="${pathIndex}"][curveIndex="${closestPoint+1}"][handleIndex="${1}"]`)
        
        previousLine.plot([...closestHelperPoints[6], ...closestHelperPoints[0]])
        previousHandle.center(...closestHelperPoints[0])

        nextLine.plot([...closestHelperPoints[7], ...closestHelperPoints[2]])
        nextHandle.center(...closestHelperPoints[2])

        hitRef.current.point = newPointGroup
        hitRef.current.pathSelect =  e.target.instance 

        hitRef.current.data = {
          type: 'point',
          path: parseInt(e.target.instance.attr('pathIndex')),
          curve: closestPoint + 1,
          selectionCurveIndex: closestPoint + 1,
        }
        
        hitRef.current.path = draw.findOne(`[pathIndex="${hitRef.current.data.path}"][type="path"]`)
        hitRef.current.path.plot(newArray)
        hitRef.current.pathSelect.plot(newArray)
      }

      if((e.target.instance.attr('type') === 'path-select' || e.target.instance.attr('type') === 'path') && e.shiftKey) {
        hitRef.current = {}
        draw.find(`[type="center-point"]`).fill('white')
        draw.find(`[pathIndex="${e.target.instance.attr('pathIndex')}"] [type="center-point"]`).fill('red')
        hitRef.current.data = {
          type: 'selection',
          path: parseInt(e.target.instance.attr('pathIndex')),
        }
      }

      if(e.target.instance.attr('type') === 'point-cephalo') {

        hitRef.current = {}

        hitRef.current.pointCephalo = e.target.instance.parent(SVG.G).front()

        hitRef.current.data = {
          index: parseInt(e.target.instance.attr('index')),
        }

      }

      if(e.target.instance.attr('type') === 'point') {

        hitRef.current = {}

        hitRef.current.point = e.target.instance.parent(SVG.G).front()
        
        draw.find('[type="center-point"]').fill('white')
        const centerPoint = hitRef.current.point.findOne('[type="center-point"]')
        centerPoint.fill('red')

        hitRef.current.data = {
          type: 'point',
          path: parseInt(hitRef.current.point.attr('pathIndex')),
          curve: parseInt(hitRef.current.point.attr('curveIndex')),
          selectionCurveIndex: parseInt(hitRef.current.point.attr('curveIndex')),
        }

        hitRef.current.path = draw.findOne(`[pathIndex="${hitRef.current.data.path}"][type="path"]`)
        hitRef.current.pathSelect = draw.findOne(`path[pathIndex="${hitRef.current.data.path}"][type="path-select"]`)

      }

      else if(e.target.instance.attr('type') === 'handle') {

        hitRef.current = {}

        e.target.instance.parent(SVG.G).front()
        
        draw.find('[type="center-point"]').fill('white')
        const centerPoint = e.target.instance.parent(SVG.G).findOne('[type="center-point"]')
        centerPoint.fill('red')

        hitRef.current.handleHover = e.target.instance

        hitRef.current.data = {
          type: 'point',
          path: parseInt(hitRef.current.handleHover.attr('pathIndex')),
          curve: parseInt(hitRef.current.handleHover.attr('curveIndex')),
          handle: parseInt(hitRef.current.handleHover.attr('handleIndex')),
          selectionCurveIndex: parseInt(hitRef.current.handleHover.parent(SVG.G).attr('curveIndex')),
        }

        console.log(hitRef.current.data.handle)

        hitRef.current.line = draw.findOne(`line[pathIndex="${hitRef.current.data.path}"][curveIndex="${hitRef.current.data.curve}"][handleIndex="${hitRef.current.data.handle}"]`)
        hitRef.current.handle = draw.findOne(`circle[type="handle-point"][pathIndex="${hitRef.current.data.path}"][curveIndex="${hitRef.current.data.curve}"][handleIndex="${hitRef.current.data.handle}"]`)
        
        hitRef.current.path = draw.findOne(`path[pathIndex="${hitRef.current.data.path}"][type="path"]`)
        hitRef.current.pathSelect = draw.findOne(`path[pathIndex="${hitRef.current.data.path}"][type="path-select"]`)

        //hitRef.current.line.front()
        hitRef.current.handle.front()
        hitRef.current.handleHover.front()
      }
    },
    mouseMove: (e, draw, hitRef, pointScale, angle) => {

      let movementX = e.screenX - previousScreenX
      let movementY = e.screenY - previousScreenY
      

      if(!hitRef.current)
        return


      if(hitRef.current.pointCephalo) {
        hitRef.current.pointCephalo.dmove(movementX, movementY)
        //hitRef.current.pointCephalo.dmove(e.movementX, e.movementY)
      }

      if(hitRef.current.point) {
        // hitRef.current.data = {
        //   ...hitRef.current.data,
        //   point: offset(e.clientX, e.clientY, draw)          
        // }

        // const s = Math.sin(-angle)
        // const c = Math.cos(-angle)

        // let x_rotated = ((e.screenX - previousScreenX) * c) - ((e.screenY - previousScreenY) * s) + previousScreenX
        // let y_rotated = ((e.screenX - previousScreenX) * s) + ((e.screenY - previousScreenY) * c) + previousScreenY

        // let movementX = (x_rotated - previousScreenX)/pointScale
        // let movementY = (y_rotated - previousScreenY)/pointScale


        hitRef.current.point.dmove(movementX, movementY)
        // hitRef.current.point.dmove(e.movementX, e.movementY)
        const pathArray = hitRef.current.path.array()

        if(hitRef.current.data.curve === 0) {
          //M first point
          pathArray[hitRef.current.data.curve][1] += movementX //e.movementX
          pathArray[hitRef.current.data.curve][2] += movementY //e.movementY

          //C first handle
          pathArray[hitRef.current.data.curve + 1][1] += movementX //e.movementX
          pathArray[hitRef.current.data.curve + 1][2] += movementY //e.movementY
        }
        else if (hitRef.current.data.curve === pathArray.length-1 ){
          //C second handle
          pathArray[hitRef.current.data.curve][3] += movementX //e.movementX
          pathArray[hitRef.current.data.curve][4] += movementY //e.movementY
          //C second last point
          pathArray[hitRef.current.data.curve][5] += movementX //e.movementX
          pathArray[hitRef.current.data.curve][6] += movementY //e.movementY
        }
        else {
          //C second handle
          pathArray[hitRef.current.data.curve][3] += movementX //e.movementX
          pathArray[hitRef.current.data.curve][4] += movementY //e.movementY
          //C second last point
          pathArray[hitRef.current.data.curve][5] += movementX //e.movementX
          pathArray[hitRef.current.data.curve][6] += movementY //e.movementY

          //C first handle
          pathArray[hitRef.current.data.curve + 1][1] += movementX //e.movementX
          pathArray[hitRef.current.data.curve + 1][2] += movementY //e.movementY
        }

        hitRef.current.path.plot(pathArray)
        hitRef.current.pathSelect.plot(pathArray)
        //we need to move the points (blue and red) 

        //we need to move the handles index 1 of curve-index === index- 1 and index 0 of curve-index === index

        //we need to move the curves curve-index === index- 1 ans curve-index === index


      } 

      else if(hitRef.current.handleHover) {

        // const s = Math.sin(-angle)
        // const c = Math.cos(-angle)

        // let x_rotated = ((e.screenX - previousScreenX) * c) - ((e.screenY - previousScreenY) * s) + previousScreenX
        // let y_rotated = ((e.screenX - previousScreenX) * s) + ((e.screenY - previousScreenY) * c) + previousScreenY

        // let movementX = (x_rotated - previousScreenX)/pointScale
        // let movementY = (y_rotated - previousScreenY)/pointScale


        hitRef.current.handle.dmove(movementX, movementY)
        hitRef.current.handleHover.dmove(movementX, movementY)
        //hitRef.current.handle.dmove(e.movementX, e.movementY)
        //hitRef.current.handleHover.dmove(e.movementX, e.movementY)

        const lineArray = hitRef.current.line.array()
        lineArray[1][0] += movementX //e.movementX
        lineArray[1][1] += movementY //e.movementY
        hitRef.current.line.plot(lineArray)

        const pathArray = hitRef.current.path.array()
        pathArray[hitRef.current.data.curve][hitRef.current.data.handle === 0 ? 1 : 3] += movementX //e.movementX
        pathArray[hitRef.current.data.curve][hitRef.current.data.handle === 0 ? 2 : 4] += movementY //e.movementY
        hitRef.current.path.plot(pathArray)
        hitRef.current.pathSelect.plot(pathArray)
      }

      previousScreenX = e.screenX
      previousScreenY = e.screenY
    },
    mouseUp: (e, draw, hitRef, cb = noop) => {
      
      const movementX = e.screenX - previousScreenX
      const movementY = e.screenY - previousScreenY

      if(!hitRef.current)
        return


      if(hitRef.current.point || hitRef.current.handleHover || hitRef.current.path) {
                //callback

        // [[[P0x, P0y], [H0x, H0y], [H1x, H1y], [P1x, P1y]], [[P1x, P1y], [H2x, H2y], [H3x, H3y], [P2x, P2y]], ...]
        const statePath = []
        // [['M', H0x, H0y], ['C', H0x, H0y, H1x, H1y, P1x, P1y], ...]
        const svgPath = hitRef.current.path.array()
        svgPath.forEach((curve, index) => {
          if(index === 0) {
            statePath[index] = []
            //['M', H0x, H0y]
            statePath[index][0] = [curve[1], curve[2]]
          }
          else if(index < svgPath.length - 1) {
            //['C', H0x, H0y, H1x, H1y, P1x, P1y]
            statePath[index - 1][1] = [curve[1], curve[2]]
            statePath[index - 1][2] = [curve[3], curve[4]]
            statePath[index - 1][3] = [curve[5], curve[6]]

            statePath[index] = []
            statePath[index][0] = [curve[5], curve[6]]
          }
          else {
            //['C', H0x, H0y, H1x, H1y, P1x, P1y]
            statePath[index - 1][1] = [curve[1], curve[2]]
            statePath[index - 1][2] = [curve[3], curve[4]]
            statePath[index - 1][3] = [curve[5], curve[6]]
          }
        })
        //[[[451.515625,115],[[451.515625,164.93132078280837]],[[466.444127272298,285.06366111216045]],[[450.515625,324]]],[[[450.515625,324]],[[406.21544704391397,432.2893238926548]],[[254.07759806190478,416.7231240146826]],[[207.515625,343]]],[[[207.515625,343]],[[169.90810646858566,283.4547623252606]],[[236.18874437176532,173.78207958117687]],[[305.515625,220]]]]

        console.log(statePath)
        cb({
          selectionType: 'point',
          selectionCurveIndex: hitRef.current.data.selectionCurveIndex,
          pathIndex: hitRef.current.data.path,
          path: statePath,
          curveIndex:  hitRef.current.data.curve,
        })
      }

      if(hitRef.current.data.type === 'selection') {
        cb({
          selectionType: 'path',
          pathIndex: hitRef.current.data.path,
        })
      }

      if(hitRef.current.pointCephalo) {
        const {cx, cy} = hitRef.current.pointCephalo.bbox()
        cb({
          pointIndex: hitRef.current.data.index,
          point: [cx, cy]
        })
      }

      hitRef.current = null

    },
    touchStart: (e, draw, hitRef) => {
      
      if(!hitRef.current)
        hitRef.current = {}
      
      if(e.touches.length === 1 && !hitRef.current.scale) {
        
        previousScreenX = e.touches[0].screenX
        previousScreenY = e.touches[0].screenY
        
        if(e.target.instance.attr('type') === 'point-cephalo') {
  
          hitRef.current.pointCephalo = e.target.instance.parent(SVG.G).front()
  
          hitRef.current.data = {
            index: parseInt(e.target.instance.attr('index')),
          }

          // hitRef.current.initialPointCephalo = hitRef.current.pointCephalo.bbox()
        }
  
        if(e.target.instance.attr('type') === 'point') {
    
          hitRef.current.point = e.target.instance.parent(SVG.G).front()
          // hitRef.current.initialPoint = hitRef.current.point.bbox()

          hitRef.current.data = {
            type: 'point',
            path: parseInt(hitRef.current.point.attr('pathIndex')),
            curve: parseInt(hitRef.current.point.attr('curveIndex'))
          }
  
          hitRef.current.path = draw.findOne(`[pathIndex="${hitRef.current.data.path}"]`)
          // hitRef.current.initialPathArray = hitRef.current.path.array()
        }
  
        else if(e.target.instance.attr('type') === 'handle') {
    
          e.target.instance.parent(SVG.G).front()
          
          hitRef.current.handleHover = e.target.instance
  
          hitRef.current.data = {
            type: 'point',
            path: parseInt(hitRef.current.handleHover.attr('pathIndex')),
            curve: parseInt(hitRef.current.handleHover.attr('curveIndex')),
            handle: parseInt(hitRef.current.handleHover.attr('handleIndex')),
          }
  
          console.log(hitRef.current.data.handle)
  
          hitRef.current.line = draw.findOne(`line[pathIndex="${hitRef.current.data.path}"][curveIndex="${hitRef.current.data.curve}"][handleIndex="${hitRef.current.data.handle}"]`)
          hitRef.current.handle = draw.findOne(`circle[type="handle-point"][pathIndex="${hitRef.current.data.path}"][curveIndex="${hitRef.current.data.curve}"][handleIndex="${hitRef.current.data.handle}"]`)
          
          hitRef.current.path = draw.findOne(`path[pathIndex="${hitRef.current.data.path}"]`)
          
          // hitRef.current.initialHandle = hitRef.current.handle.bbox()
          // hitRef.current.initialHandleHover = hitRef.current.handleHover.bbox()
          // hitRef.current.initialLineArray = hitRef.current.line.array()
          // hitRef.current.initialPathArray = hitRef.current.path.array()

          hitRef.current.line.front()
          hitRef.current.handle.front()
          hitRef.current.handleHover.front()
        }
        
      }
      if(e.touches.length === 2) {
        // console.log('cancel 2 touches')
        // //cancel previous touches action
        // if(hitRef.current.pointCephalo) {
  
          
          
        //   hitRef.current.pointCephalo.move(hitRef.current.initialPointCephalo.cx, hitRef.current.initialPointCephalo.cy)
        //   hitRef.current.pointCephalo = null
        //   hitRef.current.data = null

        //   return
  
        // }
        // if(hitRef.current.point) {
          
        //   hitRef.current.point.move(hitRef.current.initialPoint.cx, hitRef.current.initialPoint.cy)
        //   hitRef.current.path.plot(hitRef.current.initialPathArray)
        //   hitRef.current.point = null
        //   hitRef.current.path = null
        //   hitRef.current.data = null

        //   return
        // }
        
        // if(hitRef.current.handleHover) {
          
        //   hitRef.current.handle.move(hitRef.current.initialHandle.cx, hitRef.current.initialHandle.cy)
        //   hitRef.current.handleHover.move(hitRef.current.initialHandleHover.cx, hitRef.current.initialHandleHover.cy)
        //   hitRef.current.line.plot(hitRef.current.initialLineArray)
        //   hitRef.current.path.plot(hitRef.current.initialPathArray)

        //   hitRef.current.handle = null
        //   hitRef.current.handleHover = null
        //   hitRef.current.line = null
        //   hitRef.current.path = null
                    
        //   return
        // }
       
      }

      
    },
    touchMove: (e, draw, hitRef) => {

      if(e.touches.length === 1 && hitRef.current && !hitRef.current.scale) {
        
        const movementX = e.touches[0].screenX - previousScreenX
        const movementY = e.touches[0].screenY - previousScreenY
        
        previousScreenX = e.touches[0].screenX
        previousScreenY = e.touches[0].screenY

        if(!hitRef.current)
          return
    
        if(hitRef.current.pointCephalo) {
          hitRef.current.pointCephalo.dmove(movementX, movementY)
          //hitRef.current.pointCephalo.dmove(e.movementX, e.movementY)
        }
  
        if(hitRef.current.point) {
          // hitRef.current.data = {
          //   ...hitRef.current.data,
          //   point: offset(e.clientX, e.clientY, draw)          
          // }
  
          // const s = Math.sin(-angle)
          // const c = Math.cos(-angle)
  
          // let x_rotated = ((e.screenX - previousScreenX) * c) - ((e.screenY - previousScreenY) * s) + previousScreenX
          // let y_rotated = ((e.screenX - previousScreenX) * s) + ((e.screenY - previousScreenY) * c) + previousScreenY
  
          // let movementX = (x_rotated - previousScreenX)/pointScale
          // let movementY = (y_rotated - previousScreenY)/pointScale
  
  
          hitRef.current.point.dmove(movementX, movementY)
          // hitRef.current.point.dmove(e.movementX, e.movementY)
          const pathArray = hitRef.current.path.array()
  
          if(hitRef.current.data.curve === 0) {
            //M first point
            pathArray[hitRef.current.data.curve][1] += movementX //e.movementX
            pathArray[hitRef.current.data.curve][2] += movementY //e.movementY
  
            //C first handle
            pathArray[hitRef.current.data.curve + 1][1] += movementX //e.movementX
            pathArray[hitRef.current.data.curve + 1][2] += movementY //e.movementY
          }
          else if (hitRef.current.data.curve === pathArray.length-1 ){
            //C second handle
            pathArray[hitRef.current.data.curve][3] += movementX //e.movementX
            pathArray[hitRef.current.data.curve][4] += movementY //e.movementY
            //C second last point
            pathArray[hitRef.current.data.curve][5] += movementX //e.movementX
            pathArray[hitRef.current.data.curve][6] += movementY //e.movementY
          }
          else {
            //C second handle
            pathArray[hitRef.current.data.curve][3] += movementX //e.movementX
            pathArray[hitRef.current.data.curve][4] += movementY //e.movementY
            //C second last point
            pathArray[hitRef.current.data.curve][5] += movementX //e.movementX
            pathArray[hitRef.current.data.curve][6] += movementY //e.movementY
  
            //C first handle
            pathArray[hitRef.current.data.curve + 1][1] += movementX //e.movementX
            pathArray[hitRef.current.data.curve + 1][2] += movementY //e.movementY
          }
  
          hitRef.current.path.plot(pathArray)
          //we need to move the points (blue and red) 
  
          //we need to move the handles index 1 of curve-index === index- 1 and index 0 of curve-index === index
  
          //we need to move the curves curve-index === index- 1 ans curve-index === index
  
  
        } 
  
        else if(hitRef.current.handleHover) {
  
          // const s = Math.sin(-angle)
          // const c = Math.cos(-angle)
  
          // let x_rotated = ((e.screenX - previousScreenX) * c) - ((e.screenY - previousScreenY) * s) + previousScreenX
          // let y_rotated = ((e.screenX - previousScreenX) * s) + ((e.screenY - previousScreenY) * c) + previousScreenY
  
          // let movementX = (x_rotated - previousScreenX)/pointScale
          // let movementY = (y_rotated - previousScreenY)/pointScale
  
  
          hitRef.current.handle.dmove(movementX, movementY)
          hitRef.current.handleHover.dmove(movementX, movementY)
          //hitRef.current.handle.dmove(e.movementX, e.movementY)
          //hitRef.current.handleHover.dmove(e.movementX, e.movementY)
  
          const lineArray = hitRef.current.line.array()
          lineArray[1][0] += movementX //e.movementX
          lineArray[1][1] += movementY //e.movementY
          hitRef.current.line.plot(lineArray)
  
          const pathArray = hitRef.current.path.array()
          pathArray[hitRef.current.data.curve][hitRef.current.data.handle === 0 ? 1 : 3] += movementX //e.movementX
          pathArray[hitRef.current.data.curve][hitRef.current.data.handle === 0 ? 2 : 4] += movementY //e.movementY
          hitRef.current.path.plot(pathArray)
  
        }

      }
      
    },
    touchEnd: (e, draw, hitRef, cb = noop) => {
      console.log('touchstart end', e)

      if(e.touches.length === 0 && hitRef.current && !hitRef.current.scale) {
              
        const movementX = e.changedTouches[0].screenX - previousScreenX
        const movementY = e.changedTouches[0].screenY - previousScreenY        

        if(hitRef.current.point || hitRef.current.handleHover) {
                  //callback

          // [[[P0x, P0y], [H0x, H0y], [H1x, H1y], [P1x, P1y]], [[P1x, P1y], [H2x, H2y], [H3x, H3y], [P2x, P2y]], ...]
          const statePath = []
          // [['M', H0x, H0y], ['C', H0x, H0y, H1x, H1y, P1x, P1y], ...]
          const svgPath = hitRef.current.path.array()
          svgPath.forEach((curve, index) => {
            if(index === 0) {
              statePath[index] = []
              //['M', H0x, H0y]
              statePath[index][0] = [curve[1], curve[2]]
            }
            else if(index < svgPath.length - 1) {
              //['C', H0x, H0y, H1x, H1y, P1x, P1y]
              statePath[index - 1][1] = [curve[1], curve[2]]
              statePath[index - 1][2] = [curve[3], curve[4]]
              statePath[index - 1][3] = [curve[5], curve[6]]

              statePath[index] = []
              statePath[index][0] = [curve[5], curve[6]]
            }
            else {
              //['C', H0x, H0y, H1x, H1y, P1x, P1y]
              statePath[index - 1][1] = [curve[1], curve[2]]
              statePath[index - 1][2] = [curve[3], curve[4]]
              statePath[index - 1][3] = [curve[5], curve[6]]
            }
          })
          //[[[451.515625,115],[[451.515625,164.93132078280837]],[[466.444127272298,285.06366111216045]],[[450.515625,324]]],[[[450.515625,324]],[[406.21544704391397,432.2893238926548]],[[254.07759806190478,416.7231240146826]],[[207.515625,343]]],[[[207.515625,343]],[[169.90810646858566,283.4547623252606]],[[236.18874437176532,173.78207958117687]],[[305.515625,220]]]]

          // console.log(statePath)
          cb({
            pathIndex: hitRef.current.data.path,
            path: statePath
          })
          
          hitRef.current = null
          return;
        }

        if(hitRef.current.pointCephalo) {
          const {cx, cy} = hitRef.current.pointCephalo.bbox()
          cb({
            pointIndex: hitRef.current.data.index,
            point: [cx, cy]
          })
        }

        hitRef.current = null
        return;
      }

      if(e.touches.length < 2 && hitRef.current && hitRef.current.scale) {
        //hitRef.current.scale = false
      }

      if(e.touches.length === 0)
        hitRef.current = null
    },
  },
  transform: {
    mouseDown: (e, draw, hitRef) => {
      hitRef.current = {}
      previousScreenX = e.screenX
      previousScreenY = e.screenY
      
      if(e.target.id === 'profil-move-point') {
        hitRef.current.move = true
        hitRef.current.elt = e.target.instance.root().findOne('g.xray-group')
        hitRef.current.movePoint = e.target.instance
        hitRef.current.scalePoint = e.target.instance.root().findOne('#profil-scale-point')
        return
      }

      if(e.target.id === 'profil-scale-point') {
        hitRef.current.scale = true
        hitRef.current.elt = e.target.instance.root().findOne('g.xray-group')
        hitRef.current.movePoint = e.target.instance.root().findOne('#profil-move-point')
        hitRef.current.scalePoint = e.target.instance

        //hitRef.current.profilScalePoint = e.target
        //hitRef.current.elt = e.target.instance.parent(SVG.G)
        
        const point2 = offset(e.clientX, e.clientY, draw)
        //const point1Rect = hitRef.current.elt.find(`#profil-move-point`)[0].node.getBoundingClientRect()
        const point1 = [hitRef.current.movePoint.cx(), hitRef.current.movePoint.cy()] //offset(point1Rect.x + point1Rect.width/2, point1Rect.y + point1Rect.height/2, draw)
        
        hitRef.current.data = [point2[0]-point1[0], point2[1]-point1[1]]
        return
      }
    },
    mouseMove: (e, draw, hitRef) => {
      const movementX = e.screenX - previousScreenX
      const movementY = e.screenY - previousScreenY
      previousScreenX = e.screenX
      previousScreenY = e.screenY

      if(!hitRef.current)
        return

      if(hitRef.current.move === true) {
        hitRef.current.elt.translate(movementX,movementY)
        hitRef.current.movePoint.dmove(movementX,movementY)
        hitRef.current.scalePoint.dmove(movementX,movementY)
        //hitRef.current.elt.translate(e.movementX,e.movementY)
        return
      }

      if(hitRef.current.scale) {
        
        const newPoint = [hitRef.current.data[0] + movementX, hitRef.current.data[1] + movementY]
        //const newPoint = [hitRef.current.data[0] + e.movementX, hitRef.current.data[1] + e.movementY]

        const a1 = Math.atan2(hitRef.current.data[0], - hitRef.current.data[1] )*(180/Math.PI)
        const a2 = Math.atan2(newPoint[0], -newPoint[1])*(180/Math.PI)

        const d1 = Math.sqrt(Math.pow( hitRef.current.data[0],2) + Math.pow( hitRef.current.data[1],2))
        const d2 = Math.sqrt(Math.pow( newPoint[0],2) + Math.pow( newPoint[1],2))

        const originX =  hitRef.current.movePoint.cx() //hitRef.current.profilScalePoint.getAttribute('scale-origin-x')
        const originY =  hitRef.current.movePoint.cy() //hitRef.current.profilScalePoint.getAttribute('scale-origin-y')

        hitRef.current.elt.scale(d2/d1, originX, originY)
        hitRef.current.elt.rotate(a2-a1, originX, originY)

        hitRef.current.scalePoint.dmove(movementX,movementY)

        hitRef.current.data = newPoint
        return
      }
    },
    mouseUp: (e, draw, hitRef, cb = noop) => {
      const movementX = e.screenX - previousScreenX
      const movementY = e.screenY - previousScreenY
      
      if(!hitRef.current)
        return

      if(hitRef.current.move === true) {
        
        hitRef.current.elt.translate(movementX,movementY)
        hitRef.current.movePoint.dmove(movementX,movementY)
        hitRef.current.scalePoint.dmove(movementX,movementY)
        //hitRef.current.elt.translate(e.movementX,e.movementY)

        //callback
        const {translateX, translateY} = hitRef.current.elt.transform()
        cb({profil: {translate: {x: translateX, y:translateY}}})

        hitRef.current = null
        return 
      }

      if(hitRef.current.scale) {
        
        const newPoint = [hitRef.current.data[0] + movementX, hitRef.current.data[1] + movementY]
        // const newPoint = [hitRef.current.data[0] + e.movementX, hitRef.current.data[1] + e.movementY]

        const originX =  hitRef.current.movePoint.cx() //hitRef.current.profilScalePoint.getAttribute('scale-origin-x')
        const originY =  hitRef.current.movePoint.cy() //hitRef.current.profilScalePoint.getAttribute('scale-origin-y')

        const d1 = Math.sqrt(Math.pow( hitRef.current.data[0],2) + Math.pow( hitRef.current.data[1],2))
        const d2 = Math.sqrt(Math.pow( newPoint[0],2) + Math.pow( newPoint[1],2))

        hitRef.current.elt.scale(d2/d1, originX, originY)

        const a1 = Math.atan2(hitRef.current.data[0], - hitRef.current.data[1] )*(180/Math.PI)
        const a2 = Math.atan2(newPoint[0], -newPoint[1])*(180/Math.PI)

        hitRef.current.elt.rotate(a2-a1, originX, originY)

        hitRef.current.scalePoint.dmove(movementX,movementY)

        const {scaleX, rotate, translateX, translateY} = hitRef.current.elt.transform()
        //callback
        cb({profil: {scale: scaleX, rotate: rotate, translate: {x: translateX, y:translateY}}})
        hitRef.current = null
        return
      }
    },
    touchStart: (e, draw, hitRef) => {
      
      if(!hitRef.current)
        hitRef.current = {}
      
      if(e.touches.length === 1 && !hitRef.current.scale) {
        
        previousScreenX = e.touches[0].screenX
        previousScreenY = e.touches[0].screenY
        
        if(e.target.id === 'profil-move-point') {
          hitRef.current.move = true
          hitRef.current.elt = e.target.instance.root().findOne('g.xray-group')
          //hitRef.current.initialTransform = hitRef.current.elt.transform()
          hitRef.current.movePoint = e.target.instance
          //hitRef.current.initialMovePoint = hitRef.current.movePoint.bbox()
          hitRef.current.scalePoint = e.target.instance.root().findOne('#profil-scale-point')
          //hitRef.current.initialScalePoint = hitRef.current.scalePoint.bbox()

          return
        }
  
        if(e.target.id === 'profil-scale-point') {
          hitRef.current.scaleProfil = true
          hitRef.current.elt = e.target.instance.root().findOne('g.xray-group')
          hitRef.current.movePoint = e.target.instance.root().findOne('#profil-move-point')
          hitRef.current.scalePoint = e.target.instance
  
          
          const point2 = offset(e.touches[0].clientX, e.touches[0].clientY, draw)
          //const point1Rect = hitRef.current.elt.find(`#profil-move-point`)[0].node.getBoundingClientRect()
          const point1 = [hitRef.current.movePoint.cx(), hitRef.current.movePoint.cy()] //offset(point1Rect.x + point1Rect.width/2, point1Rect.y + point1Rect.height/2, draw)
          
          hitRef.current.data = [point2[0]-point1[0], point2[1]-point1[1]]
          return
        }
        
      }
      if(e.touches.length === 2) {
        // console.log('cancel 2 touches')
        // //cancel previous touches action
        
        // if(hitRef.current.move === true) {
        //   hitRef.current.elt.translate(hitRef.current.initialTransform)
        //   hitRef.current.movePoint.move(hitRef.current.initialMovePoint.cx, hitRef.current.initialMovePoint.cy)
        //   hitRef.current.scalePoint.move(hitRef.current.initialScalePoint.cx, hitRef.current.initialScalePoint.cy)
        //   hitRef.current.elt = null
        //   hitRef.current.movePoint = null
        //   hitRef.current.scalePoint = null
        //   hitRef.current.move = false
        //   //hitRef.current.elt.translate(e.movementX,e.movementY)
        //   return
        // }
        
        
       
      }

      
    },
    touchMove: (e, draw, hitRef) => {

      if(e.touches.length === 1 && hitRef.current  && !hitRef.current.scale) {
        
        const movementX = e.touches[0].screenX - previousScreenX
        const movementY = e.touches[0].screenY - previousScreenY
        
        previousScreenX = e.touches[0].screenX
        previousScreenY = e.touches[0].screenY

        if(hitRef.current.move === true) {
          hitRef.current.elt.translate(movementX,movementY)
          hitRef.current.movePoint.dmove(movementX,movementY)
          hitRef.current.scalePoint.dmove(movementX,movementY)
          //hitRef.current.elt.translate(e.movementX,e.movementY)
          return
        }

        if(hitRef.current.scaleProfil) {
          
          const newPoint = [hitRef.current.data[0] + movementX, hitRef.current.data[1] + movementY]
          //const newPoint = [hitRef.current.data[0] + e.movementX, hitRef.current.data[1] + e.movementY]

          const a1 = Math.atan2(hitRef.current.data[0], - hitRef.current.data[1] )*(180/Math.PI)
          const a2 = Math.atan2(newPoint[0], -newPoint[1])*(180/Math.PI)

          const d1 = Math.sqrt(Math.pow( hitRef.current.data[0],2) + Math.pow( hitRef.current.data[1],2))
          const d2 = Math.sqrt(Math.pow( newPoint[0],2) + Math.pow( newPoint[1],2))

          const originX =  hitRef.current.movePoint.cx() //hitRef.current.profilScalePoint.getAttribute('scale-origin-x')
          const originY =  hitRef.current.movePoint.cy() //hitRef.current.profilScalePoint.getAttribute('scale-origin-y')

          console.log(hitRef.current.elt, d2/d1, originX, originY)

          hitRef.current.elt.scale(d2/d1, originX, originY)
          hitRef.current.elt.rotate(a2-a1, originX, originY)

          hitRef.current.scalePoint.dmove(movementX,movementY)

          hitRef.current.data = newPoint
          return
        }

      }
      
    },
    touchEnd: (e, draw, hitRef, cb = noop) => {
      console.log('touchstart end', e)

      if(e.touches.length === 0 && hitRef.current && !hitRef.current.scale) {
              
        const movementX = e.changedTouches[0].screenX - previousScreenX
        const movementY = e.changedTouches[0].screenY - previousScreenY
        
        if(!hitRef.current)
          return

        if(hitRef.current.move === true) {
          
          hitRef.current.elt.translate(movementX,movementY)
          hitRef.current.movePoint.dmove(movementX,movementY)
          hitRef.current.scalePoint.dmove(movementX,movementY)
          //hitRef.current.elt.translate(e.movementX,e.movementY)

          //callback
          const {translateX, translateY} = hitRef.current.elt.transform()
          cb({profil: {translate: {x: translateX, y:translateY}}})

          hitRef.current = null
          return 
        }

        if(hitRef.current.scaleProfil) {
          
          const newPoint = [hitRef.current.data[0] + movementX, hitRef.current.data[1] + movementY]
          // const newPoint = [hitRef.current.data[0] + e.movementX, hitRef.current.data[1] + e.movementY]

          const originX =  hitRef.current.movePoint.cx() //hitRef.current.profilScalePoint.getAttribute('scale-origin-x')
          const originY =  hitRef.current.movePoint.cy() //hitRef.current.profilScalePoint.getAttribute('scale-origin-y')

          const d1 = Math.sqrt(Math.pow( hitRef.current.data[0],2) + Math.pow( hitRef.current.data[1],2))
          const d2 = Math.sqrt(Math.pow( newPoint[0],2) + Math.pow( newPoint[1],2))

          hitRef.current.elt.scale(d2/d1, originX, originY)

          const a1 = Math.atan2(hitRef.current.data[0], - hitRef.current.data[1] )*(180/Math.PI)
          const a2 = Math.atan2(newPoint[0], -newPoint[1])*(180/Math.PI)

          hitRef.current.elt.rotate(a2-a1, originX, originY)

          hitRef.current.scalePoint.dmove(movementX,movementY)

          const {scaleX, rotate, translateX, translateY} = hitRef.current.elt.transform()
          //callback
          cb({profil: {scale: scaleX, rotate: rotate, translate: {x: translateX, y:translateY}}})
          hitRef.current = null
          return
        }

        hitRef.current = null
        
      }

      if(e.touches.length < 2 && hitRef.current && hitRef.current.scale) {
        //hitRef.current.scale = false
      }

      if(e.touches.length === 0)
        hitRef.current = null
    },
  },
  pinchZoom: {
    touchStart: (e, hitRef) => {
      e.preventDefault()
      if(e.touches.length === 2) {

        if(!hitRef.current)
          hitRef.current = {}

        hitRef.current.scale = true
         
        const p1 = e.touches[0]
        const p2 = e.touches[1]

        hitRef.current.lastDist = Math.hypot(
          p1.pageX - p2.pageX,
          p1.pageY - p2.pageY
        );
      }
    },
    touchMove: (e, hitRef, setScaleState, innerRef, scrollRef) => {
      e.preventDefault()
      if(e.touches.length >= 2 && hitRef.current && hitRef.current.scale) {
                
        window.requestAnimationFrame(() => {

          if(!hitRef.current?.lastDist)
            return
          
          const p1 = e.touches[0]
          const p2 = e.touches[1]

          const dist = Math.hypot(
            p1.pageX - p2.pageX,
            p1.pageY - p2.pageY
          );
          
          const scaleFactor = dist/hitRef.current.lastDist
          hitRef.current.lastDist = dist
          
          let deltaX = 0 
          let deltaY = 0
          

          const pointX = (p1.pageX + p2.pageX)/2
          const pointY = (p1.pageY + p2.pageY)/2

          if(hitRef.current?.lastPoint) {
            deltaX = hitRef.current.lastPoint[0] - pointX
            deltaY = hitRef.current.lastPoint[1] - pointY
          }

          hitRef.current.lastPoint = [pointX, pointY]

          const {left, top} = innerRef.current.getBoundingClientRect()
          const posX = left + window.pageXOffset;
          const posY = top + window.pageYOffset;
          const elX =  pointX - posX;
          const elY =  pointY - posY;
          const offsetX = (elX * scaleFactor)-elX;
          const offsetY = (elY * scaleFactor)-elY;
          
          console.log(deltaX, deltaY)
          scrollRef.current = [offsetX + deltaX, offsetY + deltaY]

          setScaleState(scale => scale*scaleFactor)
          //toogleStickyScale(false)
        })

      }
    },
    touchEnd: (e, hitRef) => {
      e.preventDefault()
      if(hitRef.current && hitRef.current.scale)
        hitRef.current = null
    }
  }
}

const pointsOptions = (color) => ({
  color: color || '#f06'
})

const editPointsOptions = {
  color: 'blue'
}

const pathOptions = (color) => ({
  color: color || '#f06', 
  width: 2,
  linecap: 'round', 
  linejoin: 'round',
})

const toolPathOptions = (color) => ({
  color: color || '#f06', 
  width: 2,
  linecap: 'round', 
  linejoin: 'round',
  dasharray: '2, 10',
})

const editPathOptions = {
  color: 'blue', 
  width: 2,
  linecap: 'round', 
  linejoin: 'round',
}

const U6 = (u6) => {

  u6.path("M93+38C92+32+87+2+74+2C58+2+61+53+53+53C42+53+46+5+28+6C21+6+14+25+14+33C14+41+17+67+17+74C16+80+12+101+12+101C12+101+44+99+54+99C64+99+90+105+93+107C92+92+93+43+93+38").attr("vector-effect", "non-scaling-stroke")
  u6.path("M12+101C12+101-0+128+0+140C0+156+16+169+23+170C45+172+51+163+51+163C51+163+60+174+72+173C85+172+100+158+100+149C100+141+93+107+93+107").attr("vector-effect", "non-scaling-stroke")
  u6.path("M51+163L51+150L40+139").attr("vector-effect", "non-scaling-stroke")
  u6.path("M52+150L64+140").attr("vector-effect", "non-scaling-stroke")
  u6.path("M40+18C40+18+48+0+53+0C59-0+64+13+64+13").attr("vector-effect", "non-scaling-stroke")

  return u6
}

const L6 = (l6) => {

  l6.path("M11+43C11+43+20+52+25+53C30+55+43+50+49+51C56+52+65+62+67+63C70+64+89+61+89+61C89+61+80+112+72+124C65+135+48+153+38+153C24+151+56+91+49+88C42+86+19+156+5+147C-0+143-0+116+1+109C2+103+10+77+11+69C12+61+11+43+11+43Z").attr("vector-effect", "non-scaling-stroke")
  l6.path("M11+43C11+43+8+26+8+20C9+14+15+4+24+3C32+2+34+7+34+7C34+7+47-0+55+0C62+1+71+6+71+6C71+6+78+1+83+2C88+2+100+8+99+21C99+35+89+46+89+61").attr("vector-effect", "non-scaling-stroke")
  l6.path("M34+7L34+14").attr("vector-effect", "non-scaling-stroke")
  l6.path("M71+6L70+23").attr("vector-effect", "non-scaling-stroke")

  return l6
}

const U1 = (u1) => {

  u1.path("M40+311C52+312+115+228+96+170C88+166+84+165+72+166C59+168+51+176+47+176C43+176+34+167+23+168C12+169+2+181+2+181C2+181-3+191+2+207C9+224+18+227+24+247C29+262+25+311+40+311Z").attr("vector-effect", "non-scaling-stroke")
  u1.path("M2+181C2+181+19-1+48+0C77+1+96+170+96+170").attr("vector-effect", "non-scaling-stroke")
  
  return u1
}

const L1 = (l1) => {

  l1.path("M48+0C76-5+99+134+99+134C99+134+81+144+75+144C69+144+69+138+51+138C43+138+29+149+21+150C13+150+2+145+2+145C2+145-3+130+2+109C8+88+17+90+25+61C33+34+29+3+48+0Z").attr("vector-effect", "non-scaling-stroke")
  l1.path("M2+144C2+144-2+338+45+342C97+347+99+134+99+134").attr("vector-effect", "non-scaling-stroke")
  
  return l1
}

const A6L = (a6l) => {

  a6l.path("M6.90483+1.06996C11.5637+20.4997+43.149+64.3357+47.8979+63.7095C54.6065+62.8249+83.1519-0.253752+86.8645+0.000768158C94.2211+0.505109+90.457+59.864+93.0756+88.0539C93.6498+94.2356+100.471+103.074+99.8799+110.963C99.3944+117.445+95.0155+133.938+90.4074+136.075C85.3285+138.431+65.7292+127.82+65.7292+127.82C65.7292+127.82+49.3223+144.369+43.3902+143.587C31.2957+141.993+19.4021+121.038+19.4805+115.525C19.6085+106.537+23.844+96.7087+25.5832+90.1593C22.8988+74.3277+8.74324+52.1986+8.74324+52.1986C8.74324+52.1986+0.225196+35.0924+0.00591228+22.3048C-0.189122+10.9313+4.4894-3.75207+6.90483+1.06996Z").attr("vector-effect", "non-scaling-stroke")
  a6l.path("M25.6455+90.0752C34.1093+95.7651+93.4056+89.3137+93.4056+89.3137").attr("vector-effect", "non-scaling-stroke")

  return a6l
}

const B6L = (b6l) => {

  b6l.path("M15.5424+77.8845C52.3455+64.9711+77.6302+72.472+94.3377+74.8266C101.41+65.5757+99.8374+60.2719+99.9227+55.8072C100.233+39.5451+73.0359-0.248588+69.1138+0.00117027C50.7064+1.17332+36.1129+18.7724+36.1129+18.7724C36.1129+18.7724+20.6542+0.11619+3.55235+3.57008C-0.660673+19.5151-0.668889+35.2515+1.1665+42.736C5.6112+58.6926+11.9554+71.8295+15.5424+77.8845Z").attr("vector-effect", "non-scaling-stroke")
  b6l.path("M15.2893+77.3423C30.3212+111.418+18.6512+193.209+75.4169+213.644C106.265+192.191+99.088+107.691+93.9992+74.4504").attr("vector-effect", "non-scaling-stroke")

  return b6l
}

const U21 = (u21) => {

  u21.path("M48.2751+111.364C65.622+111.429+82.3635+114.685+99.7362+195.726C99.4311+228.695+5.79446+224.534+0.938136+217.223C-6.37482+140.6+30.9281+111.299+48.2751+111.364Z").attr("vector-effect", "non-scaling-stroke")
  u21.path("M10.0403+145.593C49.803-42.3849+62.4113-55.4594+86.9105+148.152").attr("vector-effect", "non-scaling-stroke")

  return u21
}

const U31 = (u31) => {

  u31.path("M99.228+11.71C104.556+58.9858+85.4923+160.429+50.3422+160.5C18.2698+160.564+6.81228+86.5362+0.147461+7.71599C-0.704021+3.8891+2.19867+0.662656+7.13167+0.424086C28.5477-0.611638+70.2781+0.266617+91.8007+2.87401C96.1585+3.40195+99.0701+10.089+99.228+11.71Z").attr("vector-effect", "non-scaling-stroke")
  u31.path("M15.9953+114.762C15.9953+114.762+29.3298+369.328+53.9885+366.103C84.0115+362.176+83.3524+124.225+83.3524+124.225").attr("vector-effect", "non-scaling-stroke")

  return u31
}

const ruler = (ruler) => {
  
  const w = 200
  const mm = 50

  
  
  
  for(let i = 0 ; i < mm+1; i++) {
    if(i%10 === 0)
      ruler.rect(2, 10).move(i*w/mm, 0).fill({color: "white", opacity : 1})
    else if(i%5 === 0)
      ruler.rect(2, 7).move(i*w/mm, 0).fill({color: "white", opacity : 1})
    else
      ruler.rect(1, 4).move(i*w/mm, 0).fill({color: "white", opacity : 1})
  }
  ruler.rect(w+2, 20).fill({color: "red", opacity : 0.3}).attr('id', 'ruler').css('cursor', 'move')

  ruler.circle(isMobile ? 15 : 4).fill({color: "red", opacity : 0.3}).stroke({width: 1, color: 'white', opacity : 0.5}).center(0, 10)
  ruler.circle(isMobile ? 15 : 4).fill({color: "red", opacity : 0.3}).stroke({width: 1, color: 'white', opacity : 0.5}).center(w+2, 10)
  
  ruler.circle(isMobile ? 25 : 10)
    .fill('transparent')
      .stroke({width: 2, color: 'transparent'})
        .center(0, 10)
          .attr('id', 'ruler-point-1')
            .css('cursor', 'pointer')
              .on('mouseenter', function () {
                this.stroke({width: 2, color: 'red'})
              }).on('mouseout', function () {
                this.stroke({width: 2, color: 'transparent'})
              })
  ruler.circle(isMobile ? 25 : 10)
    .fill('transparent')
      .stroke({width: 2, color: 'transparent'})
        .center(w+2, 10)
          .attr('id', 'ruler-point-2')
            .css('cursor', 'pointer')
              .on('mouseenter', function () {
                this.stroke({width: 2, color: 'red'})
              }).on('mouseout', function () {
                this.stroke({width: 2, color: 'transparent'})
              })

  return ruler
}

export const getTransformPoint = (point, matrix) => {
  
  const { x, y } = (Array.isArray(point) ? new Point(...point) : new Point(point.x, point.y)).transform(matrix);
  return [x, y];
}


const CephaloSVG = ({
  tool,
  scale,
  media,
  orientation,
  points,
  folderId,
  selectedPoint,
  rulerState,
  paths,
  selectedPath,
  analyses,
  analyseId,
  plans,
  mesures,
  selectedMesureId,
  profilState,
  svgStringRef,
  scaleRef,
  pxPerMmRef,
  traceIndex=0,
  notrace=false,
  imageSize={width: 0, height: 0},
  svgRef,
  setScaleState,
  innerRef,
  scrollRef,
  matrices,
  cephaloContainerRef,
  setInfo,
  ...rest
}) => {


  const classes = useStyles()
  const drawRef = useRef()
  const drawSVG = drawRef.current
  //const svgRef = useRef()
  const dispatch = useDispatch()
  
  const documentSelector = React.useMemo(makeDocumentSelector, [])
  const folder = useSelector(state => documentSelector(state, {documentId: folderId}))

  media = media === 'blank' ? 'xray' : media;

  //const photoRadioScaleSelector = React.useMemo(makePhotoRadioScaleSelector, [])
  //const photoRadioScale = useSelector(state => photoRadioScaleSelector(state, {folderId, orientation}))


  // const matriceSelector = React.useMemo(makeMatriceSelector, [])
  // const matrices = useSelector(state => matriceSelector(state, {folderId, orientation}))

  //const [mesureRef, { width, height }] = useMeasure();
  const parentRef = useRef()
  //const [rulerState, setRulerState] = useState()

  const initialRulerState = {
    translateX: 30,
    translateY: 30,
    scaleX: 1/scale,
    scaleY: 1/scale,
  }



  const selectedPointId = selectedPoint ? selectedPoint.id : undefined//Object.keys(points).find(pointId => points[pointId].type === 'point' && points[pointId].selected)
  const selectedPathId = selectedPath ? selectedPath.id : undefined
  // React.useEffect(() => {
  //   if(!scale)
  //     return
    
  //   if(!rulerState) {
  //     setRulerState({
  //       translateX: 30,
  //       translateY: 30,
  //       scaleX: 1/scale,
  //       scaleY: 1/scale,
  //     })
  //   }
  // }, [scale, rulerState])

  const [state, setState] = useState({})


  
  //ici je transforme les points stockés dans le state de l'analyse en données exploitable par CephaloSVG codé précédemment et avec svgjs
  
  //***** les points dans le state auront le système de coordonées de la radio non recadrée *******/
  
  //à partir des données de recadrage de la radio on obtient une matrice de transformation M1 donnant : coordonées de la radio recadrée =>  coordonées de la radio non recadrée
  //Quand l'utilisateur place un point sur la radio recadrée il faut donc dans le callback appliquer la matrice M1 pour stocker les points dans le système de coordonées de la radio non recadrée
  //Quand je veux afficher les points à nouveau sur la radio recadrée à partir du state je dois transformer les coordonées de la radio non recadrée vers les coordonées de la radio recadrée en appliquant la matrice inverse IM1
  //Quand l'utilisateur place le profil sur la photo il me donne une matrice de transformation M2 donnant : coordonées de la radio recadrée =>  coordonées de la photo recadrée
  //à partir des données de recadrage de la photo on obtient une matrice de transformation M3 donnant : coordonées de la photo recadrée =>  coordonées de la photo non recadrée
  //Quand je veux afficher les points à nouveau sur la photo recadrée à partir du state je dois transformer les coordonées de la radio non recadrée vers les coordonées de la photo recadrée en appliquant la matrice M4 = IM1xM2xM3
  //Quand l'utilisateur place un point sur la photo recadrée il faut donc dans le callback appliquer la matrice M3 pour stocker les points dans le système de coordonées de la photo non recadrée


  //***** Je dois stocker M4 pour être indépendant d'un nouveau recadrage de la radio et de la photo *******/

  // CXRAY  -- M1 -> OXRAY
  //   |               |
  //   M2              M4
  //   |               |
  //   v               v
  // CPHOTO -- M3 -> OPHOTO 


  //M1 et M3 sont calculées à partir des données de crop
  //M2 est donnée par la saisie de l'ulisateur
  //M4 est calculée à partir de M1 M2 et M3 


  useDeepCompareEffect(() => {

    if(!matrices)
      return

    //console.log('useDeepCompareEffect', points)

    const points_with_value = Object.keys(points).filter(pointId => points[pointId].type === 'point' && points[pointId].media === media && points[pointId].value)
    const points_state = points_with_value.map(pointId => {      
      
      let pointMatrix = new Matrix()
      if(points[pointId].media === 'xray' && media === 'xray')
        pointMatrix = matrices.IM1
      if(points[pointId].media === 'xray' && media === 'photo')
        pointMatrix = matrices.M5
      if(points[pointId].media === 'photo' && media === 'xray')
        pointMatrix = matrices.M6
      if(points[pointId].media === 'photo' && media === 'photo')
        pointMatrix = matrices.IM3
      return getTransformPoint(points[pointId].value, pointMatrix)
    })

    const points_ids = points_with_value.map(pointId => pointId)

    const UIE = points['uie'] && points['uie'].value && getTransformPoint(points['uie'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const UIA = points['uia'] && points['uia'].value && getTransformPoint(points['uia'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const U1points_state = UIE && UIA ? [UIE, UIA] : []

    const LIE = points['lie'] && points['lie'].value && getTransformPoint(points['lie'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const LIA = points['lia'] && points['lia'].value && getTransformPoint(points['lia'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const L1points_state = LIE && LIA ? [LIE, LIA] : []

    const distalU6 = points['distalU6'] && points['distalU6'].value && getTransformPoint(points['distalU6'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const apexU6 = points['apexU6'] && points['apexU6'].value && getTransformPoint(points['apexU6'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const U6points_state = distalU6 && apexU6 ? [distalU6, apexU6] : []

    const distalL6 = points['distalL6'] && points['distalL6'].value && getTransformPoint(points['distalL6'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const apexL6 = points['apexL6'] && points['apexL6'].value && getTransformPoint(points['apexL6'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const L6points_state = distalL6 && apexL6 ? [distalL6, apexL6] : []

    //frontal teeth
    const A6L = points['A6L'] && points['A6L'].value && getTransformPoint(points['A6L'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const A26 = points['A26'] && points['A26'].value && getTransformPoint(points['A26'].value, media === 'xray' ? matrices.IM1 : matrices.M5)

    const A6Lpoints_state = A6L && A26 ? [A6L, A26] : []

    const A6R = points['A6R'] && points['A6R'].value && getTransformPoint(points['A6R'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const A16 = points['A16'] && points['A16'].value && getTransformPoint(points['A16'].value, media === 'xray' ? matrices.IM1 : matrices.M5)

    const A6Rpoints_state = A6R && A16 ? [A6R, A16] : []

    const B6L = points['B6L'] && points['B6L'].value && getTransformPoint(points['B6L'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const A36 = points['A36'] && points['A36'].value && getTransformPoint(points['A36'].value, media === 'xray' ? matrices.IM1 : matrices.M5)

    const B6Lpoints_state = B6L && A36 ? [B6L, A36] : []

    const B6R = points['B6R'] && points['B6R'].value && getTransformPoint(points['B6R'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const A46 = points['A46'] && points['A46'].value && getTransformPoint(points['A46'].value, media === 'xray' ? matrices.IM1 : matrices.M5)

    const B6Rpoints_state = B6R && A46 ? [B6R, A46] : []

    const BL21 = points['BL21'] && points['BL21'].value && getTransformPoint(points['BL21'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const A21 = points['A21'] && points['A21'].value && getTransformPoint(points['A21'].value, media === 'xray' ? matrices.IM1 : matrices.M5)

    const U21points_state = BL21 && A21 ? [BL21, A21] : []

    const BL11 = points['BL11'] && points['BL11'].value && getTransformPoint(points['BL11'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const A11 = points['A11'] && points['A11'].value && getTransformPoint(points['A11'].value, media === 'xray' ? matrices.IM1 : matrices.M5)

    const U11points_state = BL11 && A11 ? [BL11, A11] : []

    const BL31 = points['BL31'] && points['BL31'].value && getTransformPoint(points['BL31'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const A31 = points['A31'] && points['A31'].value && getTransformPoint(points['A31'].value, media === 'xray' ? matrices.IM1 : matrices.M5)

    const L31points_state = BL31 && A31 ? [BL31, A31] : []

    const BL41 = points['BL41'] && points['BL41'].value && getTransformPoint(points['BL41'].value, media === 'xray' ? matrices.IM1 : matrices.M5)
    const A41 = points['A41'] && points['A41'].value && getTransformPoint(points['A41'].value, media === 'xray' ? matrices.IM1 : matrices.M5)

    const L41points_state = BL41 && A41 ? [BL41, A41] : []

    const paths_with_value = Object.keys(paths).filter(pathId => paths[pathId] && paths[pathId].value && paths[pathId].value.length)
    const paths_state = paths_with_value.flatMap(pathId => paths[pathId].value).map((path) => {
      return path.map((curve) => {
        return curve.map((pt) => {
          return getTransformPoint(pt, media === 'xray' ? matrices.IM1 : matrices.M5);
        });
      });
    });
    const paths_ids = {}
    let count = 0
    paths_with_value.forEach(pathId => {
      paths[pathId].value.forEach((path, index) => {
        paths_ids[count] = {id: pathId, index}
        count++
      })
    })

    let plans_state = []
    
    if(analyseId && analyses[analyseId] && analyses[analyseId].canvas[traceIndex]){
      analyses[analyseId].canvas[traceIndex].plans.forEach(plan => {
        if(plans[plan.id]) {
          const point1Id = plan.debord1 || plans[plan.id].point1
          const point2Id = plan.debord2 || plans[plan.id].point2
          
          if(points[point1Id] && points[point1Id].value && points[point2Id] && points[point2Id].value) {
            let planMatrix = new Matrix()
            if(plans[plan.id].media === 'xray' && media === 'xray')
              planMatrix = matrices.IM1
            if(plans[plan.id].media === 'xray' && media === 'photo')
              planMatrix = matrices.M5
            if(plans[plan.id].media === 'photo' && media === 'xray')
              planMatrix = matrices.M6
            if(plans[plan.id].media === 'photo' && media === 'photo')
              planMatrix = matrices.IM3
            
            plans_state = [...plans_state, [getTransformPoint(points[point1Id].value, planMatrix), getTransformPoint(points[point2Id].value, planMatrix), plan.id]]
          }
        }
      })
    }

    let mesures_state = []
    
    if(analyseId && analyses[analyseId] && analyses[analyseId].canvas[traceIndex]){
      analyses[analyseId].canvas[traceIndex].mesures.forEach(mesure => {
        console.log(mesure.id, mesures[mesure.id]?.value)
        if(mesures[mesure.id] && mesures[mesure.id].value && points[mesure.point] && points[mesure.point].value) {

          let mesureMatrix = new Matrix()
          if(points[mesure.point].media === 'xray' && media === 'xray')
            mesureMatrix = matrices.IM1
          if(points[mesure.point].media === 'xray' && media === 'photo')
            mesureMatrix = matrices.M5
          if(points[mesure.point].media === 'photo' && media === 'xray')
            mesureMatrix = matrices.M6
          if(points[mesure.point].media === 'photo' && media === 'photo')
            mesureMatrix = matrices.IM3

          const text = mesures[mesure.id].type === "ratio" ? (100*mesures[mesure.id].value).toFixed(0)+"%" : mesures[mesure.id].value.toFixed(0)
          mesures_state = [...mesures_state, [getTransformPoint(points[mesure.point].value, mesureMatrix), text, mesure.point, mesure.position , mesure.id]]
        }
      })
    }
    
    setState(state => {
      return {
        ...state,
        points: points_state,
        points_ids: points_ids,
        U6points: U6points_state,
        L6points: L6points_state,
        U1points: U1points_state,
        L1points: L1points_state,
        A6Lpoints: A6Lpoints_state,
        A6Rpoints: A6Rpoints_state,
        B6Lpoints: B6Lpoints_state,
        B6Rpoints: B6Rpoints_state,
        U21points: U21points_state,
        U11points: U11points_state,
        L31points: L31points_state,
        L41points: L41points_state,
        paths: paths_state,
        paths_ids: paths_ids,
        plans: plans_state,
        mesures: mesures_state,
        color: folder.color || undefined
      }
    })

  }, [points, paths, analyseId, folder, media, profilState, ...Object.values(matrices).map(M => M.toString()), traceIndex])

  
  //console.log('points', points)

  useEffect(() => {
    if(svgRef && svgRef.current)
      drawRef.current = SVG(svgRef.current)
  }, [])


  //const initialProfilState = useRef()

  //const pointScale = media === 'xray' ? 1 : profilState ? profilState.scaleX : photoRadioScale
  //const pointAngle = media === 'xray' ? 0 : profilState ? profilState.rotate * Math.PI/180: 0
  const [pointSelection, setPointSelection] = useState(false)
  const [pathSelection, setPathSelection] = useState(false)

  useKey((event) => {
    if(event.target.tagName === 'INPUT')
      return;
    
    if(event.key === 'Backspace') {
      event.preventDefault()
      if(pathSelection !== false && !isNaN(pathSelection)) {
        let pointMatrix = new Matrix()
        if(media === 'xray')
          pointMatrix = matrices.M1
        if(media === 'photo')
          pointMatrix = matrices.IM5

        const pathId = state.paths_ids[pathSelection].id
        const subPathIndex = state.paths_ids[pathSelection].index

        const paths_in_state = state.paths.filter((path, index) => {
          return state.paths_ids[index].id === pathId
        })

        const beforePath = paths_in_state.slice(0, subPathIndex).map(path => path.map((curve) => {
          return curve.map((pt) => {
            return getTransformPoint(pt, pointMatrix);
          });
        }));


        const afterPath = paths_in_state.slice(subPathIndex + 1).map(path => path.map((curve) => {
          return curve.map((pt) => {
            return getTransformPoint(pt, pointMatrix);
          });
        }));

        const newPath = [
          //on doit ajouter le path aux  paths
          ...beforePath,
          //...paths_in_state.slice(0, subPathIndex), 
          ...afterPath,
          //...paths_in_state.slice(subPathIndex + 1)
        ]

        dispatch(cephaloRoutine.setPath({
          folder: folderId, 
          orientation,            
          path: {
            [pathId]: newPath.length ? newPath : undefined
          }
        }))
        setPathSelection(false)
      }
      if(!!pointSelection) {
        let pointMatrix = new Matrix()
        if(media === 'xray')
          pointMatrix = matrices.M1
        if(media === 'photo')
          pointMatrix = matrices.IM5

        const pathId = state.paths_ids[pointSelection.pathIndex].id
        const subPathIndex = state.paths_ids[pointSelection.pathIndex].index

        const paths_in_state = state.paths.filter((path, index) => {
          return state.paths_ids[index].id === pathId
        }).map(path => path.map((curve) => {
          return curve.map((pt) => {
            return getTransformPoint(pt, pointMatrix);
          });
        }));

        const beforePath = paths_in_state.slice(0, subPathIndex)


        const afterPath = paths_in_state.slice(subPathIndex + 1)



        let newPath = paths_in_state[subPathIndex]

        const newPaths = [
          //on doit ajouter le path aux  paths
          ...beforePath,
          pointSelection.curveIndex === 0 ?
            newPath.slice(1) : 
              pointSelection.curveIndex === newPath.length ?
                newPath.slice(0, -1) :
                  [
                    ...newPath.slice(0, pointSelection.curveIndex-1),
                    [...newPath[pointSelection.curveIndex-1].slice(0,2), ...newPath[pointSelection.curveIndex].slice(2)],
                    ...newPath.slice(pointSelection.curveIndex+1)
                  ],
          ...afterPath,
        ]

        dispatch(cephaloRoutine.setPath({
          folder: folderId, 
          orientation,            
          path: {
            [pathId]: newPaths.some(path => path.length > 0) ? newPaths : undefined
          }
        }))
        
        setPointSelection(false)
      }
    }
  }, () => {}, undefined, [state ,pathSelection, Object.values(pointSelection || {}).join(), folderId, orientation])

  const rulerGroupRef = useRef()
  const {t} = useTranslation()

  useEffect(() => {

    if(!matrices)
      return
    if(!drawSVG)
      return
    
    drawSVG.clear()
    //const draw = drawSVG.group()
    
    const defs = SVG('<defs>')
    const filter = SVG('<filter id="whiteOutlineEffect" color-interpolation-filters="sRGB">')
    const feMorphology = SVG('<feMorphology in="SourceAlpha" result="MORPH" operator="dilate" radius="0.5" />')
    const feColorMatrix = SVG('<feColorMatrix in="MORPH" result="WHITENED" type="matrix" values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 1 0"/>')
    const feMerge = SVG('<feMerge>')
    const feMergeNode1 = SVG('<feMergeNode in="WHITENED"/>')
    const feMergeNode2 = SVG('<feMergeNode in="SourceGraphic"/>')

    feMorphology.addTo(filter)
    feColorMatrix.addTo(filter)

    feMergeNode1.addTo(feMerge)
    feMergeNode2.addTo(feMerge)

    feMerge.addTo(filter)

    filter.addTo(defs)

    defs.addTo(drawSVG)


    const xray = drawSVG.group().addClass('xray-group')
    const photo = drawSVG.group().addClass('photo-group')
    
    let radioPxPerMM = (202*( rulerState && rulerState.scaleX || 1))/50
    if(media === 'photo')
      radioPxPerMM*=matrices.M2.decompose().scaleX

    pxPerMmRef.current = rulerState && rulerState.scaleX ? radioPxPerMM : undefined

    let teethStroke = {};
    if(['point', 'path'].includes(tool))
      teethStroke = pathOptions()
    else if(['edit'].includes(tool))
      teethStroke = editPathOptions
    else if(['select', 'transform'].includes(tool))
      teethStroke = pathOptions(state.color)

    if(rulerState && state.U6points && state.U6points[0] && state.U6points[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // U6 = 100px width U6 = 10mm
      // U6 distal = [0,140]
      // U6 apex = [28, 6]
      const a1 = Math.atan2(state.U6points[1][0] - state.U6points[0][0], - (state.U6points[1][1] - state.U6points[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(28-0, - (6 - 140))*(180/Math.PI)

      const u6PxPerMM = 100/10
      const matrix = new Matrix()
        .scale(scale*radioPxPerMM/u6PxPerMM, 0, 140)
          .rotate(a1-a2, 0, 140)
            .translate(state.U6points[0][0]*scale, -140 + state.U6points[0][1]*scale)  

      U6(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    if(rulerState && state.L6points && state.L6points[0] && state.L6points[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // L6 = 91px width L6 = 10.5mm
      // L6 distal = [8,20]
      // L6 apex = [5, 147]
      const a1 = Math.atan2(state.L6points[1][0] - state.L6points[0][0], - (state.L6points[1][1] - state.L6points[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(5-8, - (147 - 20))*(180/Math.PI)

      const l6PxPerMM = 91/10.5
      const matrix = new Matrix()
        .scale(scale*radioPxPerMM/l6PxPerMM, 8, 20)
          .rotate(a1-a2, 8, 20)
            .translate(-8 + state.L6points[0][0]*scale, -20 + state.L6points[0][1]*scale) 

      L6(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    if(rulerState && state.U1points && state.U1points[0] && state.U1points[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // U1 = 100px width U1 (v-l) = 7mm
      // U1 bord libre = [40, 311]
      // U1 apex = [48, 0]
      const a1 = Math.atan2(state.U1points[1][0] - state.U1points[0][0], - (state.U1points[1][1] - state.U1points[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(48-40, - (0 - 311))*(180/Math.PI)

      const u1PxPerMM = 100/7
      const matrix = new Matrix()
        .scale(scale*radioPxPerMM/u1PxPerMM, 40, 311)
          .rotate(a1-a2, 40, 311)
            .translate(-40 + state.U1points[0][0]*scale, -311 + state.U1points[0][1]*scale) 

      U1(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    if(rulerState && state.L1points && state.L1points[0] && state.L1points[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // L1 = 100px width L1 = 6mm
      // L1 bord libre = [48, 0]
      // L1 apex = [45, 342]
      const a1 = Math.atan2(state.L1points[1][0] - state.L1points[0][0], - (state.L1points[1][1] - state.L1points[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(45-48, - (342 - 0))*(180/Math.PI)

      const l1PxPerMM = 100/6
      const matrix = new Matrix()
        .scale(scale*radioPxPerMM/l1PxPerMM, 48, 0)
          .rotate(a1-a2, 48, 0)
            .translate(-48 + state.L1points[0][0]*scale, -0 + state.L1points[0][1]*scale) 

      L1(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    //frontal teeth
    if(rulerState && state.A6Lpoints && state.A6Lpoints[0] && state.A6Lpoints[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // A6L = 100px width A6L = 11.5mm
      // A6L bord libre = [100, 110]
      // A6L apex = [87, 0]
      const a1 = Math.atan2(state.A6Lpoints[1][0] - state.A6Lpoints[0][0], - (state.A6Lpoints[1][1] - state.A6Lpoints[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(87-100, - (0 - 110))*(180/Math.PI)

      const l1PxPerMM = 100/11.5
      const matrix = new Matrix()
        .scale(scale*radioPxPerMM/l1PxPerMM, 100, 110)
          .rotate(a1-a2, 100, 110)
            .translate(-100 + state.A6Lpoints[0][0]*scale, -110 + state.A6Lpoints[0][1]*scale) 

      A6L(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }
    
    if(rulerState && state.A6Rpoints && state.A6Rpoints[0] && state.A6Rpoints[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // A6L = 100px width A6L = 11.5mm
      // A6L bord libre = [100, 110]
      // A6L apex = [87, 0]
      const a1 = Math.atan2(state.A6Rpoints[1][0] - state.A6Rpoints[0][0], - (state.A6Rpoints[1][1] - state.A6Rpoints[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(13-0, - (0 - 110))*(180/Math.PI)

      const l1PxPerMM = 100/11.5
      const matrix = new Matrix()
        .scale(-scale*radioPxPerMM/l1PxPerMM, scale*radioPxPerMM/l1PxPerMM, 100, 110)
          .rotate(a1-a2, 100, 110)
            .translate(-100 + state.A6Rpoints[0][0]*scale, -110 + state.A6Rpoints[0][1]*scale) 

      A6L(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    if(rulerState && state.B6Lpoints && state.B6Lpoints[0] && state.B6Lpoints[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // B6Lpoints = 100px width B6Lpoints = 10mm
      // B6Lpoints bord libre = [100, 57]
      // B6Lpoints apex = [75, 214]
      const a1 = Math.atan2(state.B6Lpoints[1][0] - state.B6Lpoints[0][0], - (state.B6Lpoints[1][1] - state.B6Lpoints[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(75-100, - (214 - 57))*(180/Math.PI)

      const l1PxPerMM = 100/10
      const matrix = new Matrix()
        .scale(scale*radioPxPerMM/l1PxPerMM, 100, 57)
          .rotate(a1-a2, 100, 57)
            .translate(-100 + state.B6Lpoints[0][0]*scale, -57 + state.B6Lpoints[0][1]*scale) 

      B6L(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    if(rulerState && state.B6Rpoints && state.B6Rpoints[0] && state.B6Rpoints[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // B6Lpoints = 100px width B6Lpoints = 10mm
      // B6Lpoints bord libre = [100, 57]
      // B6Lpoints apex = [75, 214]
      const a1 = Math.atan2(state.B6Rpoints[1][0] - state.B6Rpoints[0][0], - (state.B6Rpoints[1][1] - state.B6Rpoints[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(25-0, - (214 - 57))*(180/Math.PI)

      const l1PxPerMM = 100/10
      const matrix = new Matrix()
        .scale(-scale*radioPxPerMM/l1PxPerMM, scale*radioPxPerMM/l1PxPerMM, 100, 57)
          .rotate(a1-a2, 100, 57)
            .translate(-100 + state.B6Rpoints[0][0]*scale, -57 + state.B6Rpoints[0][1]*scale) 

      B6L(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }
    
    if(rulerState && state.U21points && state.U21points[0] && state.U21points[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // U21points = 100px width U21points = 9mm
      // U21points bord libre = [46, 222]
      // U21points apex = [55, 0]
      const a1 = Math.atan2(state.U21points[1][0] - state.U21points[0][0], - (state.U21points[1][1] - state.U21points[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(55-46, - (0 - 222))*(180/Math.PI)

      const l1PxPerMM = 100/9
      const matrix = new Matrix()
        .scale(scale*radioPxPerMM/l1PxPerMM, 46, 222)
          .rotate(a1-a2, 46, 222)
            .translate(-46 + state.U21points[0][0]*scale, -222 + state.U21points[0][1]*scale) 

      U21(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    if(rulerState && state.U11points && state.U11points[0] && state.U11points[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // U11points = 100px width U11points = 9mm
      // U11points bord libre = [46, 222]
      // U11points apex = [55, 0]
      const a1 = Math.atan2(state.U11points[1][0] - state.U11points[0][0], - (state.U11points[1][1] - state.U11points[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(45-54, - (0 - 222))*(180/Math.PI)

      const l1PxPerMM = 100/9
      const matrix = new Matrix()
        .scale(-scale*radioPxPerMM/l1PxPerMM, scale*radioPxPerMM/l1PxPerMM, 46, 222)
          .rotate(a1-a2, 46, 222)
            .translate(-46 + state.U11points[0][0]*scale, -222 + state.U11points[0][1]*scale) 

      U21(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    if(rulerState && state.L31points && state.L31points[0] && state.L31points[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // L31points = 100px width L31points = 5.5mm
      // L31points bord libre = [50, 0]
      // L31points apex = [54, 366]
      const a1 = Math.atan2(state.L31points[1][0] - state.L31points[0][0], - (state.L31points[1][1] - state.L31points[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(54-50, - (366 - 0))*(180/Math.PI)

      const l1PxPerMM = 100/5.5
      const matrix = new Matrix()
        .scale(scale*radioPxPerMM/l1PxPerMM, 50, 0)
          .rotate(a1-a2, 50, 0)
            .translate(-50 + state.L31points[0][0]*scale, -0 + state.L31points[0][1]*scale) 

      U31(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }

    if(rulerState && state.L41points && state.L41points[0] && state.L41points[1] && (!notrace || ['transform', 'edit', 'point', 'path'].includes(tool))) {
      // L41points = 100px width L41points = 5.5mm
      // L41points bord libre = [50, 0]
      // L41points apex = [54, 366]
      const a1 = Math.atan2(state.L41points[1][0] - state.L41points[0][0], - (state.L41points[1][1] - state.L41points[0][1]))*(180/Math.PI)
      const a2 = Math.atan2(46-50, - (366 - 0))*(180/Math.PI)

      const l1PxPerMM = 100/5.5
      const matrix = new Matrix()
        .scale(-scale*radioPxPerMM/l1PxPerMM, scale*radioPxPerMM/l1PxPerMM,50, 0)
          .rotate(a1-a2, 50, 0)
            .translate(-50 + state.L41points[0][0]*scale, -0 + state.L41points[0][1]*scale) 

      U31(xray.group()).stroke(teethStroke).fill('none').transform(matrix)
    }
    
    // const l6PxPerMM = 91/10.5
    // L6(draw.group()).stroke(pathOptions).fill('none').scale(scale*radioPxPerMM/l6PxPerMM, 0, 0).translate(-5,70)

    // const u1PxPerMM = 100/7
    // U1(draw.group()).stroke(pathOptions).fill('none').scale(scale*radioPxPerMM/u1PxPerMM, 0, 0).translate(160,60)

    // const l1PxPerMM = 100/6
    // L1(draw.group()).stroke(pathOptions).fill('none').scale(scale*radioPxPerMM/l1PxPerMM, 0, 0).translate(200,60)


    if(tool === 'point' && media === 'xray') {
      const matrix = rulerState ? new Matrix(rulerState).transform(matrices.IM1).scale(scale) : new Matrix(initialRulerState).scale(scale)
      let timeout;
      ruler(xray.group()).transform(matrix).on('mouseenter', function () {
        if(timeout)
          clearTimeout(timeout)
        if(rulerState) {
          setInfo({
            title: t("cephalo.Enregistrer l'étalonnage"),
            detail: t("cephalo.Effectuez un clic droit sur la règle pour enregistrer cet étalonnage")
          })
        }
        else {
          setInfo({
            title: t("cephalo.Étalonnage"),
            detail: t('cephalo.Positionnez la régle sur les repères du cephalostat pour effectuer l\'étalonnage')
          })
        }
      }).on('mouseout', function () {
        timeout = setTimeout(() => setInfo() , 1000)
      })
      
    }

    if(state.points) {
      
      const draw = media === 'xray' ? xray : photo

      if (tool === 'edit') {
        state.points.forEach((pt, index) => {
          pt = pt.map(v => v*scale)
          const g = draw.group()
          g.circle(4).fill(editPointsOptions).center(pt[0], pt[1])
          g.circle(10)
            .fill('transparent')
              .stroke({width: 2, color: 'transparent'})
                .center(pt[0], pt[1])
                    .css('cursor', 'pointer')
                      .on('mouseenter', function () {
                        this.stroke({width: 2, color: 'red'})
                      }).on('mouseout', function () {
                        this.stroke({width: 2, color: 'transparent'})
                      }).attr({
                        type: 'point-cephalo',
                        index
                      })
        })
      }
      else if(['path', 'point'].includes(tool)){
        state.points.forEach((pt) => {
          pt = pt.map(v => v*scale)
          draw.circle(4).fill(pointsOptions()).center(pt[0], pt[1])
        })
      }
      
    }

    if(state.paths) {

      const draw = xray
      if(['select'].includes(tool) && !notrace) {
        //draw plain path for viewer
        state.paths.forEach((path) => {
          const svgPath = []

          path.forEach((curve, index) => {
            curve = curve.map(c => c.map(v => v*scale))
            if(index === 0) {
              svgPath.push(['M', ...curve[0]])
            }
            svgPath.push(['C', ...curve[1], ...curve[2], ...curve[3]])
          })

          draw.path(svgPath).stroke(pathOptions(state.color)).fill('none').attr("vector-effect", "non-scaling-stroke")
        })
      }
      else if(['transform'].includes(tool)) {
        //draw plain path for viewer
        state.paths.forEach((path) => {
          const svgPath = []

          path.forEach((curve, index) => {
            curve = curve.map(c => c.map(v => v*scale))
            if(index === 0) {
              svgPath.push(['M', ...curve[0]])
            }
            svgPath.push(['C', ...curve[1], ...curve[2], ...curve[3]])
          })

          draw.path(svgPath).stroke(pathOptions(state.color)).fill('none').attr("vector-effect", "non-scaling-stroke")
        })
      }
      else if (['edit'].includes(tool)) {
        //draw plain path and curve for manipulation

        state.paths.forEach((path, pathIndex) => {

          const pathArray = []
          
          let prevGroup = draw.group().attr({
            curveIndex: 0,
            pathIndex: pathIndex,
          })
          
          const pathSelected = pathSelection !== false && !isNaN(pathSelection) && pathSelection === pathIndex

          path.forEach((curve, curveIndex) => {
            
            const curveSelected = pathSelected || (pointSelection?.pathIndex === pathIndex && pointSelection?.curveIndex - 1 === curveIndex)

            curve = curve.map(c => c.map(v => v*scale))

            const group = draw.group().attr({
              curveIndex: curveIndex + 1,
              pathIndex: pathIndex,
            })

            if(curveIndex === 0) {
              const curveSelected = pathSelected || ( pointSelection?.pathIndex === pathIndex && pointSelection?.curveIndex === curveIndex)

              pathArray.push(['M', ...curve[0]])
              prevGroup.circle(5).center(...curve[0]).fill(curveSelected ? 'red' : 'white').stroke({ width: 1, color: 'blue'}).attr("vector-effect", "non-scaling-stroke").attr({
                type: 'center-point'
              })

              prevGroup.circle(10)
                .fill('transparent')
                  .stroke({width: 2, color: 'transparent'})
                    .center(...curve[0])
                        .css('cursor', 'pointer')
                          .on('mouseenter', function () {
                            this.stroke({width: 2, color: 'red'})
                          }).on('mouseout', function () {
                            this.stroke({width: 2, color: 'transparent'})
                          }).attr({
                            type: 'point',
                            pointIndex: curveIndex,
                          }).attr("vector-effect", "non-scaling-stroke")
            }

            pathArray.push(['C', ...curve[1], ...curve[2], ...curve[3]])

            prevGroup.line(...curve[0], ...curve[1]).stroke({ width: 2, color: 'blue'}).attr({
              type: 'line',
              pathIndex: pathIndex,
              curveIndex: curveIndex + 1,
              handleIndex: 0
            }).back().attr("vector-effect", "non-scaling-stroke")
            group.line(...curve[3], ...curve[2]).stroke({ width: 2, color: 'blue' }).attr({
              type: 'line',
              pathIndex: pathIndex,
              curveIndex: curveIndex + 1,
              handleIndex: 1
            }).attr("vector-effect", "non-scaling-stroke")
            
            
            prevGroup.circle(4).center(...curve[1]).fill('white').stroke({ width: 1, color: 'blue'}).attr({
              type: 'handle-point',
              pathIndex: pathIndex,
              curveIndex: curveIndex + 1,
              handleIndex: 0
            }).attr("vector-effect", "non-scaling-stroke")
            prevGroup.circle(10)
              .fill('transparent')
                .stroke({width: 2, color: 'transparent'})
                  .center(...curve[1])
                      .css('cursor', 'pointer')
                        .on('mouseenter', function () {
                          this.stroke({width: 2, color: 'red'})
                        }).on('mouseout', function () {
                          this.stroke({width: 2, color: 'transparent'})
                        }).attr({
                          type: 'handle',
                          pathIndex: pathIndex,
                          curveIndex: curveIndex + 1,
                          handleIndex: 0
                        }).attr("vector-effect", "non-scaling-stroke")


            group.circle(4).center(...curve[2]).fill('white').stroke({ width: 1, color: 'blue'}).attr({
              type: 'handle-point',
              pathIndex: pathIndex,
              curveIndex: curveIndex + 1,
              handleIndex: 1
            }).attr("vector-effect", "non-scaling-stroke")
            group.circle(10)
              .fill('transparent')
                .stroke({width: 2, color: 'transparent'})
                  .center(...curve[2])
                      .css('cursor', 'pointer')
                        .on('mouseenter', function () {
                          this.stroke({width: 2, color: 'red'})
                        }).on('mouseout', function () {
                          this.stroke({width: 2, color: 'transparent'})
                        }).attr({
                          type: 'handle',
                          pathIndex: pathIndex,
                          curveIndex: curveIndex + 1,
                          handleIndex: 1
                        }).attr("vector-effect", "non-scaling-stroke")


            group.circle(5).center(curve[3][0], curve[3][1]).fill(curveSelected ? 'red' : 'white').stroke({ width: 1, color: 'blue'}).attr("vector-effect", "non-scaling-stroke").attr({
              type: 'center-point'
            })
            group.circle(10)
              .fill('transparent')
                .stroke({width: 2, color: 'transparent'})
                  .center(curve[3][0], curve[3][1])
                      .css('cursor', 'pointer')
                        .on('mouseenter', function () {
                          this.stroke({width: 2, color: 'red'})
                        }).on('mouseout', function () {
                          this.stroke({width: 2, color: 'transparent'})
                        }).attr({
                          type: 'point',
                          pointIndex: curveIndex + 1,
                        }).attr("vector-effect", "non-scaling-stroke")

            prevGroup = group
          })
          
          draw.path(pathArray).stroke(editPathOptions).fill('none').attr('pathIndex', pathIndex).back().attr("vector-effect", "non-scaling-stroke").attr("type", "path")

          draw.path(pathArray).stroke({
            color: 'transparent', 
            width: 5,
            linecap: 'round', 
            linejoin: 'round',
          }).fill('none').attr('pathIndex', pathIndex).back().attr("vector-effect", "non-scaling-stroke").attr("type", "path-select")
        })

      }
      else  if(['point', 'path'].includes(tool)){
        //draw dashed path for drawing
        state.paths.forEach((path) => {
          const svgPath = []

          path.forEach((curve, index) => {
            //console.log('curve 1', curve)
            curve = curve.map(c => c.map(v => v*scale))
            //console.log('curve 2', curve)
            if(index === 0) {
              svgPath.push(['M', ...curve[0]])
            }
            svgPath.push(['C', ...curve[1], ...curve[2], ...curve[3]])
          })

          draw.path(svgPath).stroke(toolPathOptions()).fill('none').attr("vector-effect", "non-scaling-stroke")
        })
      }
 
    }

    if(analyses[analyseId] && state.plans && state.plans.length &&  ['select', 'transform'].includes(tool)) {
      
      const draw = analyses[analyseId].media === 'xray' ? xray : photo
      let selectedLine = []
      state.plans.forEach(plan => {
        const planId = plan[2]
        const line = draw.line(...plan[0].map(v => v*scale), ...plan[1].map(v => v*scale)).stroke(pathOptions(state.color)).attr("vector-effect", "non-scaling-stroke")
        if(selectedMesureId && mesures[selectedMesureId] && mesures[selectedMesureId].type === "angle" && (mesures[selectedMesureId].plan1 === planId || mesures[selectedMesureId].plan2 === planId)) {
          selectedLine.push(line.stroke({color: "lime"}))
        }
      })
      if(selectedLine.length)
        selectedLine.forEach(line => line.front())
    }

    if(analyses[analyseId] && state.mesures && state.mesures.length && ['select'].includes(tool)) {
      
      const draw = analyses[analyseId].media === 'xray' ? xray : photo

      const texts_point = {}
      state.mesures.forEach(mesure => {
        const pointId = mesure[2]
        const pointLocation = mesure[3]
        const mesureId = mesure[4]

        if(texts_point[pointId] && texts_point[pointId][pointLocation]) {
          const tspan = texts_point[pointId][pointLocation].tspan(mesure[1]).newLine()
          tspan.attr("filter", "url(#whiteOutlineEffect)")
            .on('mouseenter', function () {
              dispatch(cephaloRoutine.setCurrentMesure({
                current_mesure: mesureId
              }))
            }).on('mouseout', function () {
              dispatch(cephaloRoutine.setCurrentMesure({
                current_mesure: null
              }))
            })
          if(selectedMesureId === mesureId) {
            tspan.fill('lime')
            tspan.attr("filter", "")
          }
            
        }
        else {
          if(!texts_point[pointId])
            texts_point[pointId] = {}
          
          let fontSize
          if(rulerState?.scaleX)
            fontSize = scale*5*radioPxPerMM
          else
            fontSize = scale*imageSize.height*40/1492

          const text = draw.text("").clear()
          //text.attr("filter", "url(#whiteOutlineEffect)")
          texts_point[pointId][pointLocation] = text
          text.build(true).leading(1).font({family: 'Helvetica', size: fontSize, }).fill('#000').attr("vector-effect", "non-scaling-stroke")
          const tspan = text.tspan(mesure[1])
          tspan.attr("filter", "url(#whiteOutlineEffect)")
          .on('mouseenter', function () {
            dispatch(cephaloRoutine.setCurrentMesure({
              current_mesure: mesureId
            }))
          }).on('mouseout', function () {
            dispatch(cephaloRoutine.setCurrentMesure({
              current_mesure: null
            }))
          })
          if(selectedMesureId === mesureId) {
            tspan.fill('lime').front()  
            tspan.attr("filter", "")
          }   
        }

        let {width, height} = texts_point[pointId][pointLocation].node.getBBox()
        const marge = 50*scale

        let x, y
        switch(pointLocation) {
          case 'bottom-right':
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x+marge/2, y+marge/2)
            break
          case 'bottom-left':
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x-marge/2-width, y+marge/2)
            break
          case 'bottom':
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x-width/2, y+marge/2)
            break
          case 'right':
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x+marge/2, y-height/2)
            break
          case 'left':
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x-marge/2-width, y-height/2)
            break
          case 'top':
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x-width/2, y-marge/2-height)
            break
          case 'top-left':
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x-marge/2-width, y-marge/2-height)
            break
          case 'top-right':
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x+marge/2, y-marge/2-height)
            break
          default:
            [x, y] = mesure[0].map(v => v*scale)
            texts_point[pointId][pointLocation].move(x+marge/2, y+marge/2)
        }
        //draw.text(mesure[1]).move(...mesure[0].map(v => v*scale))
        //draw.line(...plan[0].map(v => v*scale), ...plan[1].map(v => v*scale)).stroke(pathOptions)
      })

    }
    

    if(media === 'photo' && state.paths && state.paths.length) {

      if(tool === 'transform') {

        const moveRefPoint = state.paths[0][state.paths[0].length - 1][3].map(v => v*scale)
        const scaleRotateRefPoint = state.paths[0][0][0].map(v => v*scale)

        drawSVG.circle(12)
          .center(...moveRefPoint)
            .fill({color: "red", opacity : 0.3})
              .stroke({width: 1, color: 'white', opacity : 0.5}).attr('id', 'profil-move-point')
                .css('cursor', 'pointer')
                  .attr({
                    'id': 'profil-move-point',
                   })
                    .on('mouseenter', function () {
                      this.stroke({width: 2, color: 'red'})
                    }).on('mouseout', function () {
                      this.stroke({width: 2, color: 'transparent'})
                    })

        drawSVG.circle(12)
          .center(...scaleRotateRefPoint)
            .fill({color: "red", opacity : 0.3})
              .stroke({width: 1, color: 'white', opacity : 0.5})
                .attr({
                  'id': 'profil-scale-point',
                  //'scale-origin-x': moveRefPoint[0]*scale,
                  //'scale-origin-y':  moveRefPoint[1]*scale,
                }).css('cursor', 'pointer')
                      .on('mouseenter', function () {
                        this.stroke({width: 2, color: 'red'})
                      }).on('mouseout', function () {
                        this.stroke({width: 2, color: 'transparent'})
                      })

      }

      /*A voir mais je ne veux pas faire comme ça

      if(profilState) {
        const {a,b,c,d,e,f, ...rest} = profilState;
        const matrix = new Matrix({...rest, translateX: profilState.translateX*scale, translateY: profilState.translateY*scale})
        //draw.scale(profilState.scaleX,profilState.scaleY)
          //.rotate(a1-a2, 48, 0)
            //.translate(-48 + state.L1points[0][0]*scale, -0 + state.L1points[0][1]*scale) 
        xray.transform(matrix)
        //draw.transform(profilState,true).transform({scale}, true)
        //draw.transform(profilState)
      }
      else {
       //  //find transform to show trace inside canvas
       //  const parentRect = //drawSVG.node.getBoundingClientRect()//.width ? 
       //    //drawSVG.node.getBoundingClientRect() : 
       //    {width , height }//drawSVG.node.getBoundingClientRect() //parentSize
       //  const profilRect = draw.node.getBBox()

       //  const minParent = Math.min(parentRect.width, parentRect.height)
       //  const maxChild = Math.max(profilRect.width, profilRect.height)

       // //console.log('scale for photo2',drawSVG.node.getBoundingClientRect().width)

        //const tx = parentRect.width/2 - (profilRect.x + (profilRect.width/2))
        //const ty = parentRect.height/2 - (profilRect.y + (profilRect.height/2))

        //console.log('scale for photo3',tx, ty)
        //console.log(scale)
        initialProfilState.current = {
          //translateX: tx,
          //translateY: ty,
          //origin: [0,0],
          scaleX: photoRadioScale,
          scaleY: photoRadioScale,
          //position: [0,0]
        }

        

        

        const matrix = new Matrix(initialProfilState.current)
        xray.transform(matrix)//initialProfilState.current)//matrix)//matrix)
      }

      */

      // else if(initialProfilState.current){
      //   //const matrix = new Matrix(initialProfilState.current)
        
      //   //draw.transform(matrix.scale(scale))
      // }

    }

    if(svgStringRef)
      debounce(() => {
        svgStringRef.current = drawSVG.svg(function(node) {
        
          if(node.type === 'svg'){
            node.node.style.removeProperty("width");
            node.node.style.removeProperty("height");
            node.node.setAttribute('viewBox', `0, 0, ${parentRef.current ?parentRef.current.offsetWidth : 0}, ${parentRef.current ? parentRef.current.offsetHeight : 0}`);
          }

          return node
        })
      }, 200)()
    
  }, [state, rulerState, drawSVG, tool, scale, selectedMesureId, imageSize.height, notrace, pathSelection, pointSelection])

  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  const hitRef = useRef()

  const handleContextMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if(tool === 'point' && e.target.id === 'ruler') {
      console.log('show ruler context menu')
      dispatch(rulerFormActionCreator({container: cephaloContainerRef.current, rulerState}))
        
    }
  }

  useEffect(() => {
    setPointSelection(false)
    setPathSelection(false)
  }, [tool])
  
  const callBack = useCallback((result) => {
    if(tool === 'point'){
      
      if(result.ruler){
        console.log('result.ruler', result.ruler)
        const matrix = new Matrix(result.ruler).scale(1/scale).transform(matrices.M1)

        dispatch(cephaloRoutine.setRuler({
          folder: folderId, 
          orientation,  
          ruler: matrix.decompose()
        }))
        //setRulerState(matrix.scale(1/scale).decompose())
      }

      if(result.point){
        result.point = result.point.map(v => v/scale)
        
        if(selectedPointId) {
          if(points[selectedPointId].media === 'xray')
            result.point = getTransformPoint(result.point, matrices.M1)
          if(points[selectedPointId].media === 'photo')
            result.point = getTransformPoint(result.point, matrices.M3)

          dispatch(cephaloRoutine.setPoint({
            folder: folderId, 
            orientation,  
            point: {
              [selectedPointId]: {
                x: result.point[0],
                y: result.point[1]
              }
            }
          }))
        }
          
        // setState(state => {

        //   const points = state.points ? [...state.points, result.point] : [result.point]

        //   let U6points = [points[0], points[1]]
        //   let L6points = [points[2], points[3]]

        //   let U1points = [points[4], points[5]]
        //   let L1points = [points[6], points[7]]

        //   // if((state.points || []).length < 2){
        //   //   U6points.push(result.point)
        //   // }
        //   // else if((state.points || []).length < 4){
        //   //   L6points.push(result.point)
        //   // }
        //   // else if((state.points || []).length < 6){
        //   //   U1points.push(result.point)
        //   // }
        //   // else if((state.points || []).length < 8){
        //   //   L1points.push(result.point)
        //   // }

        //   return {
        //     ...state,
        //     points,//: state.points ? [...state.points, result.point] : [result.point],
        //     U6points,
        //     L6points,
        //     U1points,
        //     L1points,
        //   }
        // })
      }
    }
    else if(tool === 'path'){
      result = result.map(c => c.map(p => p.map(v =>v/scale))).map((curve) => {
        return curve.map((pt) => {
          return getTransformPoint(pt, matrices.M1);
        });
      });
      
      if(selectedPathId) {
        if(selectedPath.multiple) {
          dispatch(cephaloRoutine.setPath({
            folder: folderId, 
            orientation,            
            path: {
              [selectedPathId]: [
                //on doit ajouter le path aux  paths
                ...state.paths.filter((path, index) => {
                  return state.paths_ids[index].id === selectedPathId
                }).map(path => path.map((curve) => {
                  return curve.map((pt) => {
                    return getTransformPoint(pt, matrices.M1);
                  });
                })),
                result
              ]
            }
          }))
        } else {
          dispatch(cephaloRoutine.setPath({
            folder: folderId, 
            orientation,  
            path: {
              [selectedPathId]: [
                //on ne tient pas compte du précédent qui de toute manière n'existe pas
                result
              ]
            }
          }))
        }
      }
        

      // setState(state => {
      //   return {
      //     ...state,
      //     paths: state.paths ? [...state.paths, result] : [result],
      //   }
      // })
    }
    else if(tool === 'edit'){
      if(result.path) {
 
        let pointMatrix = new Matrix()
        if(media === 'xray')
          pointMatrix = matrices.M1
        if(media === 'photo')
          pointMatrix = matrices.IM5

        result.path = result.path.map(c => c.map(p => p.map(v =>v/scale))).map((curve) => {
          return curve.map((pt) => {
            return getTransformPoint(pt, pointMatrix);
          });
        });

        const pathId = state.paths_ids[result.pathIndex].id
        const subPathIndex = state.paths_ids[result.pathIndex].index

        const paths_in_state = state.paths.filter((path, index) => {
          return state.paths_ids[index].id === pathId
        })

        const beforePath = paths_in_state.slice(0, subPathIndex).map(path => path.map((curve) => {
          return curve.map((pt) => {
            return getTransformPoint(pt, pointMatrix);
          });
        }));


        const afterPath = paths_in_state.slice(subPathIndex + 1).map(path => path.map((curve) => {
          return curve.map((pt) => {
            return getTransformPoint(pt, pointMatrix);
          });
        }));

        dispatch(cephaloRoutine.setPath({
          folder: folderId, 
          orientation,            
          path: {
            [pathId]: [
              //on doit ajouter le path aux  paths
              ...beforePath,
              //...paths_in_state.slice(0, subPathIndex), 
              result.path, 
              ...afterPath,
              //...paths_in_state.slice(subPathIndex + 1)
            ]
          }
        }))

        // setState(state => {
        //   return {
        //     ...state,
        //     paths: [...state.paths.slice(0, result.pathIndex), result.path, ...state.paths.slice(result.pathIndex + 1)],
        //   }
        // })
      }
      else if(result.point) {
        result.point = result.point.map(v => v/scale)
        const pointId = state.points_ids[result.pointIndex]

        if(pointId) {

          let pointMatrix = new Matrix()
          if(points[pointId].media === 'xray' && media === 'xray')
            pointMatrix = matrices.M1
          if(points[pointId].media === 'xray' && media === 'photo')
            pointMatrix = matrices.IM5
          if(points[pointId].media === 'photo' && media === 'xray')
            pointMatrix = matrices.IM6
          if(points[pointId].media === 'photo' && media === 'photo')
            pointMatrix = matrices.M3
          
          result.point = getTransformPoint(result.point, pointMatrix)
        
          dispatch(cephaloRoutine.setPoint({
            folder: folderId, 
            orientation,  
            point: {
              [pointId]: {
                x: result.point[0],
                y: result.point[1]
              }
            }
          }))
        }

        // setState(state => {

        //   const points = [...state.points.slice(0, result.pointIndex), result.point, ...state.points.slice(result.pointIndex + 1)]

        //   let U6points = [points[0], points[1]]
        //   let L6points = [points[2], points[3]]

        //   let U1points = [points[4], points[5]]
        //   let L1points = [points[6], points[7]]

        //   return {
        //     ...state,
        //     points,
        //     U6points,
        //     L6points,
        //     U1points,
        //     L1points,
        //   }
        // })
      }
      
      if(result.selectionType === 'point') {
        
        setTimeout(() => {
          setPointSelection({
            type: 'point',
            pathIndex: result.pathIndex,
            curveIndex: result.selectionCurveIndex
          })
          setPathSelection(false)
        }, 0)
      }
      else if(result.selectionType === 'path') {
        setPathSelection(result.pathIndex)
        setPointSelection(false)
      }
    }
    else if(tool === 'transform') {
      if(result.profil){
        
        
        //const {translateX, translateY, scaleX, scaleY, rotate, ...rest} = result.profil

        let newM2 = matrices.M2//.translate(translateX/scale, translateY/scale)//.scale(scaleX, scaleY).rotate(rotate)
        

        if(result.profil.scale)
          newM2 = newM2.scale(result.profil.scale)
        if(result.profil.rotate)
          newM2 = newM2.rotate(result.profil.rotate)
        if(result.profil.translate)
          newM2 = newM2.translate(result.profil.translate.x/scale, result.profil.translate.y/scale)


        const newM4 = matrices.M3.multiply(
          newM2.multiply(matrices.IM1)
        )
        //matrix = matrix.multiply(matrices.M4) //matrices.M4.multiply(matrix)

        //matrix.scale(1/scale).multiply(matrices.M1)

        dispatch(cephaloRoutine.setProfilTransform({
          folder: folderId, 
          orientation,  
          profil: newM4.decompose()//{...rest, translateX: translateX/scale, translateY: translateY/scale}/*.scale(1/scale)*/
        }))
        //setRulerState(matrix.scale(1/scale).decompose())
      }
    }

  }, [tool, scale, selectedPointId, selectedPathId, state, media])
  
  const handleMouseDown = useCallback((e) => {
    if(tools[tool] && tools[tool].mouseDown)
      tools[tool].mouseDown(e, drawSVG, hitRef)
  }, [tool, drawSVG, scale])

  const handleMouseMove = useCallback((e) => {
    if(tools[tool] && tools[tool].mouseMove)
      tools[tool].mouseMove(e, drawSVG, hitRef)
  }, [tool, drawSVG, scale, ])

  const handleMouseUp = useCallback((e) => {
    if(tools[tool] && tools[tool].mouseUp)
      tools[tool].mouseUp(e, drawSVG, hitRef, callBack)
  }, [tool, drawSVG, scale, selectedPointId, selectedPathId, state, media])

  const handleTouchStart = useCallback((e) => {
    if(tools[tool] && tools[tool].touchStart)
      tools[tool].touchStart(e, drawSVG, hitRef)
    tools.pinchZoom.touchStart(e, hitRef)
  }, [tool, drawSVG, scale])

  const handleTouchMove = useCallback((e) => {
    if(tools[tool] && tools[tool].touchMove)
      tools[tool].touchMove(e, drawSVG, hitRef)
    tools.pinchZoom.touchMove(e, hitRef, setScaleState, innerRef, scrollRef)
  }, [tool, drawSVG, scale, ])

  const handleTouchEnd = useCallback((e) => {
    if(tools[tool] && tools[tool].touchEnd)
      tools[tool].touchEnd(e, drawSVG, hitRef, callBack)
    tools.pinchZoom.touchEnd(e, hitRef)
  }, [tool, drawSVG, scale, selectedPointId, selectedPathId, state, media])

  useEvent('touchstart', handleTouchStart, svgRef.current, {capture: true, passive: false})
  useEvent('touchmove', handleTouchMove, svgRef.current, {capture: true, passive: false})
  useEvent('touchend', handleTouchEnd, svgRef.current, {capture: true, passive: false})

  return (
    <div 
      ref={(ref) => {
        //mesureRef(ref)
        parentRef.current = ref
      }} 
      className={rest.className}
    >
      <svg
        ref={svgRef}
        onContextMenu={handleContextMenu}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        // onTouchStart={handleTouchStart}
        // onTouchMove={handleTouchMove}
        // onTouchEnd={handleTouchEnd}
        {...rest} 
      />
  </div>
  )

}   

 export default CephaloSVG